.color-picker-dropdown {
  width: 88px;
  height: 36px;
  border: 1px solid $color-grey;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;

  &--disabled {
    // background-color: #f5f5f5;
    cursor: not-allowed;
  }

  &__color-field {
    width: 48px;
    height: 16px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;

    & span {
      height: 1px;
      width: 100%;
      background-color: $color-dark;
    }
  }

  &__color-field-line {
    width: 15px;
    height: 2px;
    background-color: red;
  }

  &__arrow {
    // color: $color-dark;
    color: #bfbfbf;
    transform: scaleY(1.2);
  }

  &__overlay {
    background: $color-off-white;
    padding: 10px;
  }

  &__overlay-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
  }

  &__overlay-color-field {
    width: 24px;
    height: 24px;
    border: 1px solid $color-grey;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;

    & span {
      height: 1px;
      width: 100%;
      background-color: $color-dark;
    }
  }
}

body .tox-tbtn.tox-split-button__chevron {
  mask: unset;
}
