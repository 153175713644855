.themes-page {
  height: 100%;
  background-color: $color-white;

  &__content {
    padding: 24px 27px;
  }
}

.themes-list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.add-theme-btn {
  width: 192px;
  height: 365px;
  background-color: $color-off-white;
  border: 1px dashed #D5DFE8;
  border-radius: 3px;
  margin-top: 34px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: $color-primary;
}

.theme-card {
  display: flex;
  flex-direction: column;

  gap: 12px;
  align-items: center;

  &__activate {
    padding: 0 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__activate-label {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__content {
    position: relative;
    border: 1px solid $color-grey;
  }

  &__box {
    width: 200px;
    height: 136px;
    border: 1px solid #D5DFE8;
    border-radius: 3px;
    margin-bottom: 7px;
    padding: 4px;
    cursor: pointer;

    display: flex;
    flex-direction: column;

    &--active {
      border: 1px solid $color-primary;

      +.theme-card__name {
        color: $color-primary;
      }
    }
  }

  &__name {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: $color-dark;
  }

  &__logo {
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 126px;
      height: auto;
      max-height: 40px;
    }
  }

  &__color-palette {
    display: flex;
    border-radius: 3px;
    overflow: hidden;
  }

  &__color-field {
    height: 16px;
    flex: 1;
  }

  &__options {
    display: flex;
    align-items: center;
    gap: 20px;
    visibility: hidden;

    // position: absolute;
    // top: 0;
    // right: 0;
    // background-color: rgba(255, 255, 255, 0.8);
    // padding: 7px 10px;
    &-wrapper {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px 0;
      // display: flex;
      // justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0);
      transition: all .3s;
      z-index: 2;

      &--visible {
        visibility: visible;
        background: rgba(255, 255, 255, .9);

        .theme-card__options {
          visibility: visible;
        }
      }
    }
  }

  &__option-btn {
    font-size: small;
    color: $color-dark;
    cursor: pointer;
  }
}