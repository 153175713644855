.ant-select.dropdown-small,
.ant-select.dropdown-medium {
  & .ant-select-selector {
    border: 1px solid $color-grey;
    border-radius: 3px;

    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    // font-size: 14px;
    // line-height: 19px;
    color: #2a3a49;

    &:hover {
      border: 1px solid $color-dark-grey;
      border: 1px solid $color-primary;
    }
  }

  &.ant-select-focused .ant-select-selector {
    border: 1px solid $color-primary;
    border-color: $color-primary !important;
    box-shadow: none !important;
    // box-shadow: 0px 0px 5px 1px $color-primary-3 !important;
    outline: none !important;
  }
}

.ant-select.dropdown-small {
  & .ant-select-selector {
    font-size: 14px;
    line-height: 19px;
    height: 32px;
    svg {
      width: 20px;
    }
  }
}

.ant-select.dropdown-medium {
  & .ant-select-selector {
    font-size: 16px;
    line-height: 26px;
    // height: 40px;
  }
}

.block-properties {
  & .ant-select-selector {
    font-size: 14px;
    line-height: 19px;
    height: auto !important;
  }
}

.ant-select-item-option {
  font-family: $font-secondary;
}

.ant-select {
  font-family: $font-secondary;
  font-size: $font-label-size;
  font-weight: $font-label-weight;
  line-height: $font-label-line-height;
  &:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $color-primary;
  }
  &-selector {
    display: flex;
    align-items: center;
    min-height: 36px;
  }
}
// .ant-select.dropdown-medium .ant-select-selector {
//     font-size: 14px !important;
//   }

.ant-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: $color-white;
    color: $color-primary;
    font-weight: 500;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: $color-off-white;
  }
  .ant-select-item {
    font-size: $font-label-size;
    font-weight: $font-label-weight;
    line-height: $font-label-line-height;
    .anticon-check {
      display: none;
    }
  }
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.theme-editor__section,
.new-theme-builder {
  .ant-select-selection-placeholder {
    height: 100%;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $color-primary;
  box-shadow: none;
}
