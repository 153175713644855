.blocks-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.blocks-grid {
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  padding: 0 28px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;

  &__card {
    position: relative;
    // z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &:hover .qoolection__qoo-card-menu-icon {
      opacity: 1;
    }

    .block-display .text-content-container p {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__card-content {
    position: relative;
    border: 1px solid $color-grey;
  }

  &__options-wrapper {
    padding: 8px 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    transition: all .3s;
    z-index: 2;

    &--visible {
      visibility: visible;
      opacity: 1;
      background: rgba(255, 255, 255, .9);
    }
  }

  &__options-item {
    width: 100%;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: all 0.2s ease;

    & svg {
      color: $color-darker-grey;
      transition: all 0.2s ease;
    }

    &>span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $color-dark;
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: $color-light-grey;

      &>svg,
      &>span {
        color: $color-primary;
      }
    }
  }
}

.history_modal_container {
  display: flex !important;

  &__card {
    display: flex;
    margin: 10px;
    flex-direction: column;
  }
}

.block-display {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(16, 1fr);
  border: 1px solid #D5DFE8;
  border-radius: 3px;

  &--no-border {
    border: none;
  }

  & .text-content-container p {
    margin-bottom: 0;
  }
}