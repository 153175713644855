.block-builder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  //margin: 0 auto;
  // margin-left: 400px;
  width: 100%;
  position: relative;
  overflow-y: auto;
  padding: 10px;

  // &--templates-collapsed {
  //   margin: 0 auto 0 390px;
  // }

  & .preview-input {
    height: 32px;
    border-radius: 3px;

    &:focus-visible {
      outline: none;
    }
  }
}

.block-sizes-sidebar {
  overflow: auto;

  &__heading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 14px 24px;
    margin: 0;
  }

  &__grid {
    padding: 0 12px 24px 24px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 18px;
  }
}

.block-preview {
  max-width: 650px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  transform: translateY(0px);
  margin-right: 25%;

  @media only screen and (max-width: 1800px) {
    margin-right: 30%;
  }

  & .text-content-container p {
    margin-bottom: 0;
  }
}

.react-grid-item > .react-resizable-handle {
  width: 8px;
  height: 8px;
  border: 1px solid $color-primary;
  background-image: none;
  background-color: $color-white;
  // transform: none;

  &::after {
    display: none;
  }
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: -4px;
  transform: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: -4px;
  transform: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  right: -4px;
  bottom: -4px;
  transform: none;
}

.react-resizable-handle.element,
.react-resizable-handle.canvas,
.react-resizable-handle {
  display: none;
}

.react-resizable-hide .react-resizable-handle {
  display: none !important;
}

.selected-canvas {
  & .react-resizable-handle.react-resizable-handle-se {
    display: block;
  }
}

.selected-element {
  & > .react-resizable-handle.react-resizable-handle-s,
  & > .react-resizable-handle.react-resizable-handle-e {
    display: block;
  }

  & .text-element-container ~ .react-resizable-handle.react-resizable-handle-s {
    display: none;
  }
}

.layers-panel {
  position: absolute;
  right: 60px;
  bottom: 132px;
  width: 344px;
  background: $color-white;

  &--in-sidebar {
    position: static;
    width: 355px;
  }

  & .ant-collapse-header-text {
    flex: 1;
  }

  & .ant-collapse-item {
    border-bottom: 1px solid $color-grey;

    & .ant-collapse-header {
      color: $color-darker-grey;
      padding: 20px 25px 20px 18px;
      align-items: center;
    }

    &.ant-collapse-item-active .ant-collapse-header {
      border-bottom: 1px solid $color-grey;
    }

    &.ant-collapse-item-active {
      & .ant-collapse-header,
      & .pre-title {
        color: $color-dark;
      }

      & .pre-title.text-background__label {
        color: $color-darker-grey;
      }
    }
  }

  & .ant-collapse-content-box {
    padding: 0 !important;
  }

  & .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: auto;
  }

  & .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 24px;
  }

  & .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-darker-grey;
    transition: all 0.2s ease-in;

    &:hover {
      color: $color-primary;
    }
  }

  &__list {
    max-height: 336px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__item {
    padding: 13px;
    display: flex;
    align-items: center;
    height: 56px;
    transition: all 0.2s ease-in;

    &--selected {
      border: 1px solid $color-primary;
    }

    &:hover {
      background-color: $color-light-grey;
    }

    &:hover .layers-panel__delete-icon,
    &:hover .layers-panel__show-hide-icon {
      opacity: 1;
    }
  }

  &__arrows {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__arrow {
    cursor: pointer;
    color: $color-dark;

    &--disabled {
      color: $color-medium-grey;
      cursor: auto;
    }
  }

  &__delete-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
    color: $color-darker-grey;
    opacity: 0;
    transition: all 0.2s ease-in;

    &:hover {
      color: $color-primary;
    }

    &:first-of-type {
      margin-left: auto;
    }
  }

  &__show-hide-icon {
    margin-left: 10px;
    cursor: pointer;
    color: $color-darker-grey;
    opacity: 0;
    transition: all 0.2s ease-in;

    &:hover {
      color: $color-primary;
    }

    &--hidden {
      color: $color-grey;
    }
  }

  &__block-type-icon {
    margin-left: 28px;
    color: $color-darker-grey;
    display: flex;
    align-items: center;
  }

  &__block-type-name {
    margin-left: 12px;
    color: $color-dark;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  &__canvas {
    padding: 13px;
    padding-left: 54px;
    display: flex;
    align-items: center;
    height: 56px;

    &--selected {
      border: 1px solid $color-primary;
    }
  }

  &__canvas-icon {
    color: $color-darker-grey;
  }

  &__actions {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__action-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $color-darker-grey;

    &:hover,
    &:hover span {
      color: $color-primary;
    }

    &--disabled {
      color: $color-medium-grey;
      cursor: auto;

      & span {
        color: $color-medium-grey !important;
      }

      &:hover {
        color: $color-medium-grey;

        & span {
          color: $color-medium-grey;
        }
      }
    }

    & span {
      color: $color-dark;
    }
  }
}

.right-panels-btn-container {
  position: absolute;
  right: 60px;
  bottom: 60px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.right-panels-btn {
  width: 56px;
  height: 56px;
  background-color: $color-white;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-darker-grey;
  transition: all 0.2s ease-in-out;

  &--selected,
  &:hover {
    color: $color-primary;
  }
}

.right-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 355px;
  background-color: $color-white;
  border-left: 1px solid $color-grey;
}

.grid-box {
  position: relative;

  &__top-left-dot {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 2px;
    height: 2px;
    background: $color-dark;
    border-radius: 50%;
  }

  &__top-right-dot {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 2px;
    height: 2px;
    background: $color-dark;
    border-radius: 50%;
  }

  &__bottom-left-dot {
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: 2px;
    height: 2px;
    background: $color-dark;
    border-radius: 50%;
  }

  &__bottom-right-dot {
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 2px;
    height: 2px;
    background: $color-dark;
    border-radius: 50%;
  }
}

.block-templates-sidebar {
  &__filters {
    padding: 22px 12px 22px 22px;
  }

  &__grid {
    padding: 0px 12px 22px 22px;
  }

  &__trigger {
    position: absolute;
    top: 0;
    right: -36px;
  }
}

.templates-trigger {
  position: absolute;
  top: 0;
  left: 400px;
  cursor: pointer;
  padding: 22px 13px;
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: bolder;
  z-index: 100;

  & span:last-of-type {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    opacity: 0.5;
    margin-left: 16px;
  }

  &--collapsed {
    left: 10px;

    & span:last-of-type {
      font-weight: bold;
      color: #3b8de0;
      opacity: 1;
    }
  }
}

.block-properties {
  // padding-bottom: 200px;

  & .ant-collapse-item {
    border-bottom: 1px solid $color-grey;

    & .ant-collapse-header {
      color: $color-dark-grey;
      padding: 20px 25px 20px 18px;
    }

    &.ant-collapse-item-active {
      & .ant-collapse-header,
      & .pre-title {
        color: $color-dark;
      }

      & .pre-title.text-background__label {
        color: $color-darker-grey;
      }
    }

    &:hover .pre-title {
      color: $color-dark-grey-hover;
    }
  }

  // & .ant-collapse-header {
  //   padding: 20px 25px 20px 18px !important;

  //   & .ant-collapse-extra {
  //     position: absolute;
  //     top: 30%;
  //     right: 16px;
  //   }
  // }

  & .ant-collapse-content-box {
    padding: 24px !important;
    padding-top: 12px !important;
  }
}

.block-templates {
  display: flex;
  flex-direction: column;
  max-height: 345px;
  overflow-y: auto;

  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 22px);
    grid-template-rows: repeat(8, 22px);
    grid-auto-rows: 22px;
    grid-gap: 2px;
    margin-top: 20px;
  }

  &__add-btn {
    grid-row: auto / span 4;
    grid-column: auto / span 4;
    background: $color-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $color-dark;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }

  &__template {
    background: $color-grey;
    cursor: pointer;

    & .text-content-container > p {
      margin-bottom: 0;
    }
  }
}

.text-formatting {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__item {
    flex-basis: 40px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    display: flex;
    align-items: center;
    color: $color-dark;
    padding: 0 12px;
    cursor: pointer;
    font-family: $font-primary;

    &--heading {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 37px;
    }

    &--subheading {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    &--body {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.text-padding {
  &__icons {
    display: flex;
    align-items: center;
  }

  &__btn {
    width: 48px;
    height: 48px;
    border-radius: 3px;
    color: $color-dark;
    cursor: pointer;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;

    &:hover,
    &--selected {
      border-color: $color-grey;
      background: $color-light-grey;
    }

    &--selected {
      color: $color-primary;
    }

    &--disabled {
      cursor: not-allowed;
      color: $color-darker-grey;

      &:hover {
        border-color: transparent;
        background: none;
      }
    }

    & span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    & .ant-slider {
      flex: 1;
    }
  }
}

.text-background {
  &__color {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
  }

  &__bg-color {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__border {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__border-inputs {
    display: flex;
    align-items: center;
  }

  &__border-thickness.input-small.ant-input-number {
    width: 48px;
    border: none !important;
    border-radius: 0 !important;
    border-top: 1px solid $color-grey !important;
    border-bottom: 1px solid $color-grey !important;

    &:hover {
      border: none !important;
      border-top: 1px solid $color-dark-grey !important;
      border-bottom: 1px solid $color-dark-grey !important;
    }
  }

  &__border-type {
    width: 82px;
  }

  &__checkbox {
    margin-bottom: 16px;
  }

  &__form-font {
    width: 200px;
  }
}

.bg-images {
  margin: 16px -18px 16px 0;

  &__search {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;
    max-width: 306px;
  }

  &__search-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      margin: 0;
    }
  }

  &__upload {
    width: 94px;
    height: 94px;
    border: 1px dashed $color-grey;
    border-radius: 3px;
    background: $color-off-white;
    color: $color-primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    & span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

    &:hover {
      border-color: $color-primary;
    }
  }

  &__image {
    width: 94px;
    height: 94px;
    cursor: pointer;
  }
}

.compliance {
  &__category {
    margin: 12px 0;
  }

  &__search-filter-input {
    margin: 8px 0;

    & svg {
      width: 13px;
      height: 13px;
    }
  }

  &__items-list {
    padding: 12px;
    max-height: 220px;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
  }

  &__item-title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__modal {
    width: 400px;
    margin-bottom: 50px;

    & .ant-tabs-tab {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-dark;
      padding: 0 20px 16px 20px;
      // padding-bottom: 16px;

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $color-primary;
      }
    }

    & .ant-tabs-nav {
      &::before {
        display: none;
      }
    }

    & .ant-tabs-nav-list {
      border-bottom: 1px solid $color-grey;
    }

    & .ant-tabs-ink-bar {
      background: $color-primary;
    }
  }

  &__modal-title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    margin-bottom: 27px;
  }
}

.icons-library {
  &__categories {
    width: 100%;
    margin: 8px 0;
  }

  &__icon-types {
    display: flex;
    gap: 5px;

    & .choice-btn {
      flex: 1;
    }
  }

  &__icons-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 18px;
    margin: 16px 0;
    max-height: 450px;
    overflow-y: auto;

    & img {
      cursor: pointer;
    }
  }
}

.icon-size-color {
  &__slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    & .ant-slider {
      flex: 1;
    }
  }

  &__color-title {
    margin: 12px 0;
  }
}

.grid-options {
  &__row {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;

    &:first-of-type {
      margin-bottom: 18px;
    }

    &--spb {
      justify-content: space-between;
      margin-bottom: 8px !important;
    }
  }

  &__label {
    flex-basis: 84px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 8px;

    & svg {
      color: $color-primary;
      cursor: pointer;
    }
  }

  &__ISI-size {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & .dropdown-small {
    flex: 1;
  }
}

.grid-size-popup {
  width: 328px;
  height: 464px;
  padding: 24px 56px 20px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__phone {
    width: 130px;
    height: 268px;
    background-image: url('../../../src/icons/phone-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-primary;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 0 6px;
  }

  &__header,
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-primary;
  }

  &__header {
    flex-basis: 32px;
  }

  &__grid {
    background: $color-grey;
    border: 1px dashed $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    flex: 1;
  }

  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    color: $color-dark;
    margin-bottom: 7px;
  }

  &__text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__navigation {
    display: flex;
    gap: 4px;
    margin-top: auto;
  }

  &__nav-circle {
    width: 8px;
    height: 8px;
    background: $color-grey;
    border-radius: 3px;
    cursor: pointer;

    &--active {
      background: $color-primary;
    }
  }
}

.add-info-form {
  &__item {
    margin-bottom: 12px;

    & .dropdown-small {
      width: 100%;
    }
  }

  &__label {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin-bottom: 4px;
  }
}

.block-padding {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
  }
}

.preview-buttons {
  display: flex;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);

  &__button {
    border-radius: 6px;
    position: relative;
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    background-color: transparent;
    color: #000000;
    border: 1px solid #3b8de0;
    z-index: 1;

    &--selected {
      font-weight: bold;
      background-color: #3b8de0;
      color: #fff;
      z-index: 2;
    }

    &:first-of-type {
      transform: translateX(4px);
    }

    &:last-of-type {
      transform: translateX(-4px);
    }
  }
}

.block-in-preview *[contentEditable='true']:focus,
.demo-inline *[contentEditable='true']:hover {
  outline: 2px solid #3b8de0;
}

.drag-handle {
  font-size: 24px;
  position: absolute;
  top: 45%;
  left: -30px;
  cursor: grab;
  z-index: 20;
}

.inner-drag-handle {
  font-size: 24px;
  position: absolute;
  top: -24px;
  left: 50%;
  cursor: grab;
  z-index: 20;
}

.divider-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-props-panel {
  // display: flex;
  // flex-direction: column;

  &__slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 20px;

    .ant-slider {
      width: 100%;
    }
  }

  &__border-inputs {
    display: flex;
    align-items: center;
  }

  &__border-thickness.input-small.ant-input-number {
    width: 48px;
    border: none !important;
    border-radius: 0 !important;
    border-top: 1px solid $color-grey !important;
    border-bottom: 1px solid $color-grey !important;

    &:hover {
      border: none !important;
      border-top: 1px solid $color-dark-grey !important;
      border-bottom: 1px solid $color-dark-grey !important;
    }
  }

  &__border-type {
    width: 82px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    margin-bottom: 12px;
  }

  & h4 {
    margin: 0;
    margin-right: 20px;
  }

  .pre-title {
    margin-bottom: 10px;

    &:first-child {
      margin-bottom: 0;
    }
  }
}

.divider-templates {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__container {
    cursor: pointer;
    height: 32px;
    // background-color: $color-light-grey;
    border: 1px solid $color-grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.buttons-templates {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6px;

  &__container {
    width: 142px;
    height: 43px;
    // background-color: $color-light-grey;
    border: 1px solid $color-grey;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button > p {
    margin-bottom: 0;
  }
}

.forms-templates {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 280px;
  overflow-y: auto;

  &__container {
    width: 146px;
    height: 146px;
    background-color: $color-light-grey;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.surveys-templates {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 280px;
  overflow-y: auto;

  &__container {
    width: 146px;
    height: 56px;
    background-color: $color-light-grey;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button-props-panel {
  display: flex;
  flex-direction: column;

  &__slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .ant-slider {
      width: 100%;
    }
  }

  &__section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.625rem;
    margin-bottom: 1.25rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }

  &__label {
    margin: 0;
  }

  &__input {
    flex: 1;
  }

  &__btn-sizes {
    display: flex;
    // margin-bottom: 14px;
  }

  &__btn-size {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 3px;
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }

    &--active {
      border: 1px solid $color-primary-3;
      background: $color-light-grey;
      color: $color-primary;
    }

    &--disabled {
      cursor: not-allowed;
      color: $color-darker-grey;
    }

    &-label {
      display: block;
      font-size: 12px;
      line-height: 16px;
    }

    &-icon {
      display: block;
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__color {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    // margin-bottom: 16px;
  }

  &__btn-color {
    display: flex;
    flex-direction: column;
    gap: 0.38rem;
  }

  &__border {
    display: flex;
    flex-direction: column;
    gap: 0.38rem;
  }

  &__border-inputs {
    display: flex;
    align-items: center;
  }

  &__border-thickness.input-small.ant-input-number {
    width: 48px;
    border: none !important;
    border-radius: 0 !important;
    border-top: 1px solid $color-grey !important;
    border-bottom: 1px solid $color-grey !important;

    &:hover {
      border: none !important;
      border-top: 1px solid $color-dark-grey !important;
      border-bottom: 1px solid $color-dark-grey !important;
    }
  }

  &__border-type {
    width: 82px;
  }

  &__btn-shape {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__btn-shape-choice {
    height: 32px !important;

    &:first-child {
      border-radius: 3px 0 0 3px;
      border-right: 0;
    }

    &:nth-child(2) {
      border-radius: 0;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
      border-left: 0;
    }
  }

  &__btn-effects {
    &-color {
      margin-bottom: 20px;
    }

    &-choices {
      margin-bottom: 20px;
    }

    &-choice {
      height: 32px !important;
      flex-basis: 50% !important;

      &:first-child {
        border-radius: 3px 0 0 3px;
        border-right: 0;
      }

      &:nth-child(2) {
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
        border-left: 0;
      }
    }

    &-color {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &__link {
    .ant-select {
      width: 100%;
    }

    .ant-input-prefix {
      margin-right: 8px;
    }

    &-selected {
      margin-bottom: 20px;
    }

    &-type-label {
      display: block;
      margin-bottom: 11px;
    }

    &-type-input-first {
      margin-bottom: 12px;
    }

    &-type-remove {
      margin-top: 8px;
    }

    & .ant-upload.ant-upload-select {
      display: block;
    }
  }
}

.add-button {
  background: $color-primary;
  border-radius: 3px;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 131.2%;
  color: $color-white;
  width: 80px;
  height: 32px;

  & .add-button-text {
    width: 100%;
    margin-left: -15px;
  }
}

.fieldType {
  & .ant-select-item-option-content,
  & .ant-select-selection-item {
    display: flex;
    align-items: center;

    & span {
      margin-left: 5px;
    }
  }
}

.emailInput .ant-input-prefix {
  margin-right: 10px;
}

.iconButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
}

.fieldCol {
  display: flex;
  align-items: center;
}

.fieldCol:last-of-type {
  justify-content: flex-end;
}

.surveyRequired {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.removeOptionIcon {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.endnoteList {
  height: 180px;
  overflow-y: scroll;
  margin-top: 10px;
}

.endnote {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
}

.previewBox {
  width: 100%;
  background-color: $color-light-grey;
  padding: 10px;
  margin-bottom: 10px;
}

.previewTitle {
  color: $color-darker-grey;
  font-size: 0.85em;
}

.referenceError {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
