.inner-layout {
  height: 100%;
  background: $color-light-grey;
}

.sidebar-inner {
  background-color: $color-white;
  border-right: 1px solid $color-grey;

  // max-height: calc(100vh - 80px);
  // overflow-y: auto;
  // scrollbar-gutter: stable;
  .block-properties {
    // scrollbar-gutter: stable;
    // height: calc(100vh - 136px);
    height: 100%;
    max-height: calc(100vh - 80px);
    overflow-y: auto !important;

    // &::-webkit-scrollbar {
    //   width: 13px!important;
    //   &-thumb {
    //     border-right: 7px solid $color-light-grey!important;
    //   }
    // }
    .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
    .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
      font-size: 10px !important;
    }
  }

  &__category-field {
    height: 56px;
    // border-bottom: 1px solid #D5DFE8;
    padding: 0 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    transition: all 0.2s ease-in-out;

    &--active,
    &:hover {
      background-color: $color-light-grey;
    }

    &--active .team-kit__icon {
      color: $color-primary;
    }
  }

  &__required-icon {
    color: $color-warning-red;
    background: #fff;
    border-radius: 50%;
  }

  &__container-justify-spbet {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // @media only screen and (max-width: 1500px) {
  //   flex: 0 0 310px !important;
  //   max-width: 310px !important;
  //   min-width: 310px !important;
  //   width: 310px !important;
  // }
}

.header-inner {
  padding: 0;
  background-color: $color-white;
  height: 56px;
  border-bottom: 1px solid #d5dfe8;
}
