.qoo-builder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  padding: 10px;
  position: relative;

  &__block-btns {
    position: absolute;
    top: 8px;
    right: 25px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.header-footers-edit-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  &__edit-btn {
    cursor: pointer;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-primary;
  }
}

.header-footers {
  display: flex;
  flex-wrap: wrap;
  width: 310px;
  max-width: 310px;
  gap: 10px;
  max-height: 432px;
  overflow-y: auto;
}

.qoo-menu-panel {
  &__row {
    margin-bottom: 16px;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__link-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__link-dropdown {
    width: 125px;
    min-width: 125px;
  }

  &__top-inputs {
    position: relative;
  }

  &__remove-icon {
    width: 8px;
    height: 8px;
    position: absolute;
    right: 8px;
    top: 12px;
    cursor: pointer;
  }

  &__bottom-inputs {
    display: flex;
    align-items: center;

    & .input-small {
      width: auto;
    }
  }
}

.general-qoo-info {
  padding: 20px 26px;

  &__heading {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  &__submit-btn {
    width: 100%;
    max-width: 374px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: capitalize;
  }
}

.qoo-bg-collapse {
  // padding-bottom: 200px;

  & .ant-collapse-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  & .ant-collapse-header {
    padding: 20px 25px 20px 18px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #2e4257;

    & .ant-collapse-extra {
      position: absolute;
      top: 30%;
      right: 16px;
    }
  }

  & .ant-collapse-content-box {
    padding: 18px !important;
  }
}

.qoo-header-footer {
  padding: 15px 88px 20px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .ant-checkbox-wrapper {
    margin-right: auto !important;
  }

  &__checkbox-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    color: #2e4257;
  }

  & .ant-select-lg {
    width: 270px;
    margin: 0 20px;
  }

  &__edit {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #3b8de0;
    cursor: pointer;
  }
}

.qoo-preview {
  // margin-right: 144px;
  // margin-top: 40px;
  // border-radius: 10px;
  display: grid;
  // grid-template-columns: repeat(6, 64px);
  // grid-template-rows: repeat(11, 64px);
  // background-color: #fff;
  position: relative;

  & .text-content-container p {
    margin-bottom: 0 !important;
  }

  &__header {
    background-color: #3b8de0;
    padding: 0 30px;
    border-radius: 10px 10px 0 0;
    z-index: 1;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
  }

  &__header-title {
    grid-column: 2 / span 1;
    color: $wht;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  &__header-menu-btn {
    grid-column: 3 / span 1;
    color: $wht;
    justify-self: end;
  }

  &__footer {
    background-color: #3b8de0;
    padding: 0 30px;
    border-radius: 0 0 10px 10px;
    z-index: 1;

    display: grid;
    //grid-template-columns: repeat(3, 1fr);
    place-items: center;
  }

  &__footer-title {
    color: $wht;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  &__draggable-block.selected-element {
    border: 1px solid $color-primary !important;
    box-shadow: 0 0 0.5rem $color-dark-grey;
  }

  &__draggable-block:hover &__options {
    opacity: 1;
  }

  &__draggable-block .tox-tinymce {
    border: none;
  }

  &__options {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    color: #000000;
    transition: opacity 0.2s ease-in;
  }

  &__options-icon {
    cursor: pointer;
    margin-left: 10px;
  }

  &__edit-dropdown {
    width: 342px;
    cursor: pointer;
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #3b8de0;
    padding: 10px;
  }
}

.text-element-container {
  & .text-content-container {
    & > p {
      margin-bottom: 0;
    }
  }
}

.text-content-container {
  & > p {
    margin-bottom: 0;
  }
}

.qoo-link {
  &__label {
    margin: 20px 0 10px 0;
    text-transform: unset;
  }
}

.block-event {
  &__element {
    font-family: $font-secondary;
    width: 100%;
    margin-bottom: 0.625rem;
  }
  &__list {
    padding-bottom: 1rem;
  }
  &__list-item {
    font-family: $font-secondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__list-item-actions {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
  &__actions {
    display: flex;
    gap: 0.5rem;
  }
  &__button {
    margin-top: 0.625rem;
  }
  &__form-item {
    margin: 0;
  }
}

.ant-slider + span {
  min-width: 26px;
}
