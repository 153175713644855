.ant-radio-checked {

  & .ant-radio-inner {
    border-color: $color-primary;
    background: $color-primary;
  }

  &::after {
    border: 1px solid $color-primary;
  }
}

.ant-radio-inner {
  &::after {
    background-color: $color-white;
  }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: $color-primary;
}

.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.2);
}