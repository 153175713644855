.image-crop-modal {
  height: 1000px;

  &__cropper {
    height: 60%;
    position: relative;
  }

  &__tools {
    margin-bottom: 40px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }
}