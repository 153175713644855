.qoolection-builder {
  height: 100%;
  max-height: calc(100vh - 136px);
  overflow-y: auto;
  padding: 16px 24px;
  background-color: $color-white;

  &__icons {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
  }
  &__icon {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    &--active {
      background: #D5DFE8;
      border: 1px solid #D5DFE8;
      border-radius: 3px;
    }
    svg {
      color: $color-primary;
    }
  }

  &__qoos-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__qoo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  &__qoo-card {
    width: 140px;
    height: 257px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    position: relative;
  }

  &__qoo-number {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 3px;
    background-color: $color-primary;
    color: $color-white;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__qoo-movetool {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
  }

  &__qoo-title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__themes-dropdown {
    width: 100%;
    margin-bottom: 12px;
    .ant-select-selector {
      height: 32px!important;
    }
  }

  &__checkbox {
    margin-bottom: 14px;
  }

  &__link-dropdown {
    .ant-select-selector {
      width: 96px!important;
      border-radius: 3px 0 0 3px!important;
    }
    .ant-select-selection-item {
      padding-right: 10px!important;
    }
    .ant-select-arrow {
      right: 8px;
    }
  }

  &__link-input {
    border-radius: 0 3px 3px 0;
    border-left: none;
    &:focus,
    &:hover {
      border-color: $color-primary;
    }
  }

  &__link-name-input {
    margin-bottom: 8px;
    &:focus,
    &:hover {
      border-color: $color-primary;
    }
  }
}

.theme-option {
  display: flex;
  justify-content: space-between;
  &__colors {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
  &__color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}
.qoolection-add-link-btn {
  margin-bottom: 16px;
}

.unify-theme-btn {
  height: 32px;
  line-height: normal;
  font-size: 14px;
  font-weight: 600;
}

.edit-block-inner-header--qoolections {
  .qoolections__back-btn {
    padding: 4px 21px;
    svg {
      margin-left: 0;
    }
  }
}

.qoolection {
  &__qoo-card {
    .text-content-container p {
      margin-bottom: 0;
    }
  }
}