.edit-theme-page {
  height: 100%;
  background-color: $color-white;
}

.advanced-mode {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-kit {
  &__icon {
    margin-left: 12px;
    width: 20px;
    height: 20px;
    color: $color-dark-grey;
  }

  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: $color-dark;
    margin: 0;
  }
}

.edit-theme-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-theme-header {
  height: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  &__save-btn {
    width: 165px;
    font-weight: 600;
  }

  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    cursor: pointer;
  }
}

.edit-theme-content {
  background-color: $color-white;
  display: flex;
}

.ISI-title-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 1.5em;
    height: 1.5em;
  }
}

.new-theme-builder {
  flex-basis: 900px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  &__step-1,
  &__step-2,
  &__step-3,
  &__step-4,
  &__step-5,
  &__step-6,
  &__step-7,
  &__step-8 {
    padding: 24px 28px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
  }

  &__step-subtitle {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color-dark;
    margin: 24px 0;

    & span:first-of-type {
      color: $color-primary;
    }
  }

  &__intro-text {
    margin-bottom: 20px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: $color-dark;
  }

  &__bottom-row {
    margin-top: auto;
    margin-bottom: 40px;
    padding: 0 28px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &__step-btn {
    width: 165px;
  }

  &__color-presets-btn {
    color: $color-primary;
    font-weight: 700;
    cursor: pointer;
  }

  &__small-text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__hf-section {
    margin-bottom: 36px;

    & > .new-theme-builder__small-text {
      margin-top: 8px;
    }
  }

  &__hf-section-row {
    display: flex;
    align-items: center;
    gap: 60px;

    & > .new-theme-builder__intro-text {
      margin-bottom: 0;
    }
  }

  &__hf-presets-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__hf-preset-container {
    width: 310px;
    height: 74px;
    border: 1px solid #d5dfe8;
    border-radius: 4px;
    cursor: pointer;
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--selected {
      border: 1px solid $color-primary;
    }
  }

  &__hf-style-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__hf-style-label {
    flex-basis: 252px;
    // flex-basis: 100px;
    padding-left: 50px;

    &--fl-start {
      align-self: center;
      min-width: 220px;
      max-width: 220px;
    }
  }

  &__hf-toolbar {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__hf-toolbar-group {
    display: flex;
    align-items: center;
  }

  &__hf-logo-select {
    width: 300px;
  }

  &__hf-menuicon-select {
    width: 60px;
  }

  &__hf-text-align-container {
    width: 100px;
    height: 154px;
    border: 1px solid #d5dfe8;
    border-radius: 3px;
    padding: 15px;
    cursor: pointer;

    &--selected {
      border-color: $color-primary;
    }
  }

  &__hf-text-align-item {
    height: 100%;
    background-color: $color-primary-3;
    padding: 24px 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    &::after {
      content: 'x';
      position: absolute;
      top: 0;
      right: 3px;
      color: $color-primary;
    }

    & > div {
      height: 4px;
      background-color: $color-primary;

      &:nth-of-type(odd) {
        width: 50%;
      }

      &:nth-of-type(even) {
        width: 67%;
      }
    }
  }

  &__hf-menu-font-select {
    width: 200px;
  }

  &__hf-menu-font-size-select {
    width: 100px;
  }

  &__hf-footer-size {
    // padding-left: 72px;
    padding-left: 50px;

    &:first-of-type {
      margin-bottom: 8px;
    }
  }

  &__hf-footer-font-select {
    width: 200px;
  }

  &__hf-footer-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 332px;
  }

  &__hf-footer-bottom-inputs {
    display: flex;
    align-items: center;
  }

  &__hf-footer-linkto-select {
    width: 110px;
    flex: 0 0 110px;
  }

  &__hf-footer-external-link {
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 15px;
      height: 15px;
    }
  }

  &__hf-isititle-input {
    width: 332px;
  }

  &__hf-isiicon-select {
    width: 76px;
  }
}

.step-navigation {
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  gap: 33px;

  &__step {
    width: 40px;
    height: 40px;
    border: 1px solid $color-grey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &--active {
      background-color: $color-primary;
      border-color: $color-primary;

      & .step-navigation__step-nr {
        color: $color-white;
        font-weight: 700;
      }
    }

    &:not(:last-of-type)::after {
      content: '';
      width: 33px;
      height: 1px;
      background-color: $color-grey;
      position: absolute;
      left: 39px;
      top: 50%;
    }
  }

  &__step-nr {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $color-dark;

    &--future {
      color: #aeb8c0;
    }
  }
}

.theme-editor {
  flex-basis: 900px;
  max-height: calc(100vh - 136px);
  overflow-y: auto;
  padding: 24px 36px 24px 28px;

  &__section {
    border-bottom: 1px solid $color-grey;
    padding-bottom: 36px;
  }
}

.theme-preview {
  flex-basis: 760px;
  // background-color: $color-off-white;
  display: flex;
  justify-content: center;

  &__phone-container {
    margin-top: 78px;
    position: relative;
    height: 662px;
  }

  &__phone {
    // width: 328px;
    width: 323px;
    // height: 666px;
    height: 662px;
    background-image: url('../../../src/icons/phone_3.svg');
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: #D9D9D9;
    // background-blend-mode: overlay;
    position: relative;
    // border-radius: 64px;
    // border-radius: 32px;
    overflow: hidden;
  }

  &__top-bar {
    // width: 98%;
    width: 95%;
    // height: 42px;
    height: 34px;
    background-image: url('../../../src/icons/pinpng1.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #d9d9d9;
    background-blend-mode: screen;

    position: absolute;
    // top: 5px;
    top: 0;
    // left: 2px;
    left: 6px;
    z-index: 1;
    display: none;
  }

  &__black-bg {
    width: 96%;
    height: 100%;
    background-image: url('../../../src/icons/black-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #d9d9d9;
    background-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 6px;
    z-index: 0;
    display: none;
  }

  &__qoo-content {
    position: absolute;
    // top: 48px;
    top: 35px;
    // left: 10px;
    left: 7px;
    // border-radius: 0 0 24px 24px;
    overflow: hidden;
    border-bottom: 10px solid #d9d9d9;
  }

  &__heading-container {
    padding: 1.5em 1em;
    grid-column: 1 / -1;
    grid-row: 2 / span 2;

    & > h1 {
      font-size: 2.25em;
      line-height: 112%;
    }
  }

  &__subheading-container {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 0 1em;

    &.sub1 {
      grid-row: 4 / span 1;
    }

    &.sub2 {
      grid-row: 6 / span 1;
    }

    & > h2 {
      font-size: 1.5em;
      line-height: 150%;
      margin-bottom: 0;
    }
  }

  &__bodytext-container {
    grid-column: 2 / -1;
    padding: 0 2em;

    &.bod1 {
      grid-row: 5 / span 1;
    }

    &.bod2 {
      grid-row: 7 / span 1;
    }

    & > p {
      font-size: 0.875em;
      margin-bottom: 0;
    }
  }

  &__btn-container {
    grid-column: 1 / -1;
    grid-row: 8 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25em;
  }

  &__info {
    padding: 12px;
    color: $color-white;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}

.theme-preview-footer {
  p {
    margin-bottom: 0.625em;
    line-height: 1;
  }
}

.edit-theme-save-modal {
  & .ant-form-item.ant-row {
    justify-content: space-between;
  }

  & .ant-form-item-label-left {
    width: 130px;
    max-width: 130px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin: 0;
  }

  &__input {
    width: 300px;
  }
}

.colors-tab {
  // padding: 24px 30px;
  // height: 100%;
  // display: flex;

  .chrome-picker {
    box-shadow: none !important;
    font-family: 'Noto Sans', sans-serif !important;
  }

  &__color-picking {
    padding: 24px 0;
  }

  &__color-palette {
    display: flex;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  &__color-palette-field-container {
    margin-right: 8px;
    border-radius: 3px;
    position: relative;

    &:hover .colors-tab__delete-color-btn {
      display: flex;
    }
  }

  &__color-palette-field {
    width: 80px;
    height: 40px;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: $color-primary;
    background: $color-off-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__delete-color-btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: 0px 2px 5px 0px #000000;
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 1;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
  }

  &__assignment-link {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: $color-primary;
    margin-bottom: 25px;
    cursor: pointer;
  }

  &__color-picker {
    margin-bottom: 20px;

    & .sketch-picker {
      box-shadow: none !important;
      padding: 0 !important;
    }
  }

  &__presets {
    margin-bottom: 30px;
  }

  &__presets-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    max-width: 672px;
    margin-top: 20px;
  }

  &__preset-container {
    width: 208px;
    height: 56px;
    border: 1px solid #d5dfe8;
    border-radius: 3px;
    cursor: pointer;
    padding: 8px;
  }

  &__preset {
    display: flex;
    border-radius: 3px;
    overflow: hidden;
  }

  &__preset-color-field {
    height: 40px;
    flex: 1;
  }

  &__assignment {
    // flex-basis: 975px;
    padding: 24px 0;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;

    &--editor {
      padding: 0;
      max-height: 80px;
      // flex-wrap: wrap;
      @media screen and (max-width: 1900px) {
        max-height: unset;
        flex-wrap: wrap;
        gap: 12px;
      }

      & > .colors-tab__assignment-row:nth-of-type(even) {
        margin-bottom: 0;
      }
    }
  }

  &__preview {
    flex-basis: 530px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-off-white;
  }

  &__assignment-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // margin-bottom: 16px;
    gap: 8px;
    margin-right: 16px;
  }

  &__row-title {
    flex-basis: 120px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    // margin-right: 30px;
    @media screen and (max-width: 1900px) {
      width: 115px;
    }
  }

  &__btn-container {
    display: flex;
    gap: 8px;
    margin-bottom: 38px;
    margin-top: auto;
  }

  &__save-cnc-btn {
    width: 160px;
  }
}

.typography-tab-new {
  padding: 24px 0;

  & .pre-title {
    margin-bottom: 24px;
  }

  &__row {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__text-label {
    width: 170px;
    height: 32px;
    // background-color: $color-off-white;
    border-radius: 3px;
    border: 1px solid $color-grey;
    padding: 6px 12px;

    &--empty {
      background-color: transparent;
      border: none;
    }

    & > span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: $color-dark;
    }
  }

  &__font-select {
    width: 272px;
  }

  &__font-select-placeholder {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;

    & > svg {
      width: 16px;
      height: 16px;
      color: $color-darker-grey;
    }

    & > span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $color-dark-grey;
    }
  }

  &__font-upload {
    padding: 6px 12px;
    border-bottom: 1px solid $color-light-grey;
  }

  &__font-upload-text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $color-primary;
    cursor: pointer;
  }

  &__add-font {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $color-primary;
  }

  &__selected-font {
    width: 272px;
    height: 32px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__delete-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $color-grey;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $color-primary;
    }
  }
}

.typography-tab {
  height: 100%;
  max-height: calc(100vh - 136px);
  overflow-y: hidden;
  display: flex;

  .ant-collapse-header-text {
    display: block;
    width: 100%;
  }

  &__fonts-list {
    flex-basis: 830px;

    padding: 24px 40px 24px 30px;
    height: 100%;
    border-right: 1px solid $color-grey;
    overflow-y: auto;
  }

  &__my-fonts {
    // width: 272px;

    height: 100%;
    border-right: 1px solid $color-grey;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__font-filters {
    padding: 22px 0;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  &__text-filter {
    width: 200px;
  }

  &__select {
    width: 160px;

    &:first-of-type {
      margin-right: 8px;
    }
  }

  &__fonts-list-collapse {
    background-color: $color-white;
    border: none;

    & .ant-collapse-item {
      border-bottom: 1px solid $color-grey;
    }

    & .ant-collapse-item .ant-collapse-header-text .down-caret-icon {
      font-size: 12px;
      margin-left: 12px;
      display: none;
      vertical-align: middle;
    }

    & .ant-collapse-item .ant-collapse-header-text:hover .down-caret-icon {
      display: inline-block;
    }

    & .ant-collapse-item-active .ant-collapse-header-text .down-caret-icon {
      display: inline-block;
      transform: rotate(180deg);
    }

    & .ant-collapse-extra {
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%);
    }

    & .ant-collapse-header {
      padding: 9px 22px 13px 22px !important;
    }

    & .ant-collapse-content {
      border-top: 1px solid $color-grey;
    }

    & .ant-collapse-content-box {
      padding: 0;
    }
  }

  &__fonts-list-panel {
    &--selected {
      & .ant-collapse-header {
        background-color: $color-light-grey;
      }
    }

    &--no-content {
      & .ant-collapse-content {
        border-top: none;
      }
    }
  }

  &__font-variant {
    padding: 10px 32px 12px 54px;
    display: flex;
    justify-content: space-between;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-grey;
    }

    &--selected {
      background-color: $color-light-grey;
    }
  }

  &__my-fonts-header {
    padding: 24px 16px 22px 16px;
  }

  &__my-fonts-item {
    padding: 8px 16px 10px 16px;
    border-bottom: 1px solid $color-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    &--last {
      margin-bottom: 30px;
    }
  }

  &__default-font {
    width: 200px;
  }

  &__my-fonts-name {
    font-size: 16px;
    line-height: 160%;
    color: $color-dark;
  }

  &__my-fonts-remove {
    font-size: 10px;
    color: $color-dark;
    cursor: pointer;
    align-self: center;
  }

  &__upload-btn-container {
    // margin: auto 15px 100px 15px;
    width: 138px;
    margin-left: auto;

    & .ant-upload {
      width: 100%;
    }
  }
}

.loader-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-tab {
  // padding: 24px 0;

  & .pre-title {
    margin-bottom: 24px;
  }

  &__msg {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin-top: 18px;
    margin-bottom: 21px;
  }

  &__logos {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    & .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }

    & .ant-upload.ant-upload-drag {
      background: $color-off-white;
      border: 1px dashed $color-grey;
      border-radius: 3px;

      &:hover {
        border: 1px dashed $color-primary;

        & .logo-tab__upload .anticon {
          color: $color-primary;
        }
      }
    }
  }

  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 128px;

    background: $color-off-white;
    border: 1px dashed $color-grey;
    border-radius: 3px;
    color: $color-dark;

    &:hover {
      border: 1px dashed $color-primary;
      color: $color-primary;
    }
  }
}

.logo-card {
  position: relative;

  &:hover &__delete {
    display: block;
  }

  &__logo-container {
    width: 200px;
    height: 128px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    // margin-bottom: 7px;
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    &--selected {
      border-color: $color-primary;
      background: $color-light-grey;
    }

    & img {
      max-width: 100%;
      max-height: 100%;
    }

    &:hover {
      background: $color-light-grey;
      cursor: pointer;
    }
  }

  &__delete {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 4px 7px;
    display: none;
  }

  &__logo-name {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: $color-dark;
  }
}

.header-footer-tab {
  display: flex;
  height: 100%;
  max-height: calc(100vh - 136px);
  overflow-y: hidden;
}

.headers-footers {
  padding: 24px 30px;
  height: 100%;
  width: 100%;

  &__list {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__create-new {
    width: 200px;
    height: 333px;
    background: $color-off-white;
    border: 1px dashed $color-grey;
    border-radius: 3px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & span:last-of-type {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: $color-primary;
    }

    & svg {
      color: $color-primary;
      margin-bottom: 8px;
    }
  }
}

.h-f-create-new {
  display: flex;
  width: 100%;

  &__tabs {
    width: 974px;
    padding: 20px 55px 20px 30px;
    overflow-y: auto;

    @media only screen and (max-width: 1800px) {
      width: 730px;
    }

    &--in-modal {
      max-height: 875px;
    }

    & .ant-tabs-tab {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-dark;
      padding: 0 20px 16px 20px;
      // padding-bottom: 16px;

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $color-primary;
      }
    }

    & .ant-tabs-nav {
      &::before {
        display: none;
      }
    }

    & .ant-tabs-nav-list {
      border-bottom: 1px solid $color-grey;
    }

    & .ant-tabs-ink-bar {
      background: $color-primary;
      bottom: -1px !important;
    }
  }

  &__preview {
    flex-grow: 1;
    background: $color-off-white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 1920px) {
      align-items: flex-start;
      padding-left: 20px;
    }

    &--in-modal {
      justify-content: flex-start;
      padding-top: 86px;
    }
  }

  &__section {
    padding: 24px 0 32px 0;
    border-bottom: 1px solid $color-grey;
  }

  &__section-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__section-title-number {
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  &__section-title-text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $color-dark;
  }

  &__choice-container {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }

  &__choice-divider {
    height: 32px;
    width: 1px;
    background: $color-grey;
    margin: 0 12px;
  }

  &__style-row {
    display: flex;
    flex-direction: column;
    gap: 17px;

    &--mb-lg {
      margin-bottom: 24px;
    }

    &--mb-md {
      margin-bottom: 16px;
    }

    &--mb-sm {
      margin-bottom: 8px;
    }
  }

  &__style-row-title {
    width: 130px;
    max-width: 130px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin: 0;

    &--with-tiny {
      align-self: flex-start;
      margin-top: 10px;
    }
  }

  &__toolbar {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__toolbar-group {
    display: flex;
    align-items: center;
  }

  &__logo-dropdown {
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: 3px;
    width: 200px;
    height: 32px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  &__logo-dropdown-text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__logo-dropdown-selected-img {
    width: 60px;
    height: auto;
    max-height: 24px;
  }

  &__logo-dropdown-arrow {
    color: $color-dark;
  }

  &__logo-dropdown-overlay {
    padding: 18px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 290px;
    background: $color-off-white;
  }

  &__logo-dropdown-logo {
    width: 122px;
    height: 78px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    // margin-bottom: 7px;
    padding: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__fonts-dropdown {
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: 3px 0 0 3px;
    width: 200px;
    height: 32px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  &__fonts-dropdown-text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
  }

  &__fonts-dropdown-arrow {
    color: $color-dark;
  }

  &__fontlist-dropdown-overlay {
    background: $color-off-white;
  }

  &__fontlist-dropdown-font {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    &:hover {
      background: $color-light-grey;
    }
  }

  &__top-header-text-input {
    width: 288px;
  }

  &__top-header-link-input {
    width: 208px;
  }

  &__font-size-dropdown {
    background: $color-white;
    border: 1px solid $color-grey;
    // border-radius: 3px 0 0 3px;
    width: 104px;
    height: 32px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  &__font-size-dropdown-text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__font-size-dropdown-arrow {
    color: $color-dark;
  }

  &__font-size-dropdown-overlay {
    background: $color-off-white;
  }

  &__font-size-dropdown-overlay-item {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    &:hover {
      background: $color-light-grey;
    }
  }

  &__textContent-input {
    width: 304px;
  }

  &__menu-icons-dropdown {
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: 3px 0 0 3px;
    width: 56px;
    height: 32px;
    padding: 0 8px 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  &__menu-icons-dropdown-arrow {
    color: $color-dark;
  }

  &__menu-icons-dropdown-overlay {
    background: $color-off-white;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 12px;
    align-items: center;
    max-width: 240px;
  }

  &__menu-icons-dropdown-overlay-item {
    width: 30px;
    height: 30px;
    background: $color-white;
    border: 1px solid transparent;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $color-light-grey;
      border: 1px solid $color-grey;
    }
  }

  &__presets-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 654px;
    gap: 8px;
  }

  &__preset-container {
    width: 323px;
    padding: 15px 0;
    border: 1px solid $color-grey;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--selected {
      border: 1px solid $color-primary;
    }
  }

  &__preset-top-header {
    padding: 0 15px;
    height: 23px;
    background-color: $color-primary;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $color-white;
    align-self: flex-start;
  }

  &__preset {
    width: 282px;
    height: 44px;
    background: $color-light-grey;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  &__menu-preview-checkbox {
    margin-left: auto;
  }

  &__text-align-btn {
    width: 32px;
    height: 32px;
    border: 1px solid transparent;
    border-radius: 3px;
    color: $color-dark;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    &:hover,
    &--selected {
      background: $color-light-grey;
      border: 1px solid $color-grey;
      color: $color-primary;
    }
  }

  &__menu-presets-list {
    padding: 24px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-width: 768px;
  }

  &__menu-preset-container {
    width: 112px;
    height: 176px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--selected {
      border: 1px solid $color-primary;
    }
  }

  &__menu-preset-grid {
    background: $color-light-grey;
    width: 79px;
    height: 142px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }

  &__menu-preset-menu {
    background: $color-primary-3;
  }

  &__column-num-dropdown {
    background: $color-white;
    border: 1px solid $color-grey;
    // border-radius: 3px 0 0 3px;
    width: 200px;
    height: 32px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  &__column-num-dropdown-text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__column-num-dropdown-arrow {
    color: $color-dark;
  }

  &__column-num-dropdown-overlay {
    background: $color-off-white;
  }

  &__column-num-dropdown-overlay-item {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    &:hover {
      background: $color-light-grey;
    }
  }

  &__footer-link-dropdown {
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: 3px 0 0 3px;
    width: 96px;
    height: 32px;
    padding: 0 4px 0 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  &__footer-link-dropdown-text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    text-transform: capitalize;
  }

  &__footer-link-dropdown-arrow {
    color: $color-dark;
  }

  &__footer-link-dropdown-overlay {
    background: $color-off-white;
  }

  &__footer-link-dropdown-overlay-item {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    &:hover {
      background: $color-light-grey;
    }
  }

  &__footer-link-input {
    width: 208px;
  }

  &__isi-footer-textContent-input {
    width: 490px;
  }

  &__col-num-select {
    width: 200px;
  }

  &__qoo {
    width: 360px;
    height: 600px;
    display: grid;
    grid-template-rows: auto repeat(7, 1fr) auto auto;
    grid-template-columns: repeat(6, 1fr);
    background-color: $color-white;
    position: relative;
    overflow: hidden;

    &--small {
      width: 200px;
      height: 333px;
      border: 1px solid $color-grey;
      border-radius: 3px;
      cursor: pointer;
    }

    &--extra-small {
      width: 146px;
      height: 211px;
      border: 1px solid $color-grey;
      border-radius: 3px;
      cursor: pointer;
    }

    &--selected {
      border: 1px solid $color-primary;
    }

    &:hover .h-f-create-new__qoo-edit-delete-container {
      display: flex;
    }
  }

  &__qoo-header-container {
    grid-row: 1 / span 1;
    grid-column: 1 / span 6;
  }

  &__qoo-header {
    height: 64px;
    padding: 15px 18px;

    &--small {
      height: 35px;
      padding: 0 10px;

      & svg {
        width: 10px;
      }
    }

    &--extra-small {
      height: 26px;
      padding: 0 7px;

      & svg {
        width: 7px;
      }
    }

    & svg {
      vertical-align: middle;
    }
  }

  &__qoo-header-dummy-logo {
    font-family: $font-primary;
    font-size: 25px;
    margin: 0;

    &--small {
      font-size: 14px;
    }

    &--extra-small {
      font-size: 10px;
    }
  }

  &__qoo-top-header {
    height: 24px;
    padding: 1px 15px 0 15px;
    font-size: 14px;
    line-height: 21px;

    &--small {
      height: 13px;
      padding: 1px 8px 0 8px;
      font-size: 7px;
      line-height: 11px;
    }

    &--extra-small {
      height: 9px;
      padding: 1px 6px 0 6px;
      font-size: 5px;
      line-height: 8px;
    }
  }

  &__qoo-footer {
    grid-column: 1 / span 6;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__qoo-ISI-container {
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  &__qoo-ISI-title {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--small {
      padding: 0 5px;
    }

    &--extra-small {
      padding: 0 4px;
    }
  }

  &__qoo-edit-delete-container {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: auto;
    background: $color-off-white;
  }

  &__qoo-edit-delete {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $color-dark;

    &:hover {
      color: $color-primary;
    }
  }

  &__qoo-check-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-primary;
  }

  &__buttons-container {
    display: flex;
    margin-top: 50px;
    gap: 8px;
  }

  &__save-cnc-btn {
    min-width: 160px;
  }

  &__external-link,
  &__external-link:link,
  &__external-link:active,
  &__external-link:visited {
    color: $color-dark;
    cursor: pointer;
    margin-left: 8px;

    &:hover {
      color: $color-primary;
    }

    & svg {
      vertical-align: middle;
      color: $color-primary;
    }
  }

  &__add-col-btn {
    width: 303px;
  }
}

.navigation-tab {
  display: flex;
  height: 100%;

  &__inputs {
    flex-basis: 974px;
    padding: 20px 55px 20px 30px;
  }

  &__preview {
    width: 530px;
    background: $color-off-white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  &__preview-label {
    position: absolute;
    left: 40px;
    top: 40px;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Noto Sans', sans-serif;
    color: #2a3a49;
  }

  &__input-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:first-of-type {
      margin-top: 20px;
    }
  }

  &__input-row-title {
    width: 130px;
    max-width: 130px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin: 0;
  }

  &__toolbar {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__select {
    width: 200px;
  }
}

.h-f-create-new__menu-preset-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5px;
  padding-top: 15px;
  position: relative;

  .preset-menu-line {
    width: 70%;
    height: 4px;
    margin: 5px;
    background: $color-primary;
  }

  .preset-menu-line-short {
    width: 50%;
  }

  .preset-menu-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 7px;
    right: 1px;

    .preset-menu-x-part1 {
      display: block;
      width: 6px;
      height: 1px;
      transform: rotate(45deg);
      background: $color-primary;
      position: absolute;
      left: 0;
    }

    .preset-menu-x-part2 {
      display: block;
      width: 6px;
      height: 1px;
      transform: rotate(-45deg);
      background: $color-primary;
      position: absolute;
      left: 0;
    }
  }
}

.h-f-create-new__menu-preset-container:nth-child(4) {
  .preset-menu-x {
    top: -8px;
  }
}

@media screen and (max-width: 2125px) {
  .open-menu-content-width {
    width: fit-content;
    min-width: 772px;
  }
}

.flex-basis-correction-footer {
  &__hf-style-label {
    flex-basis: 100px !important;
    padding-left: 50px;
  }
}

.radio-isi-correction {
  padding-left: 50px;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #2a3a49;
}

.isi-page-correction {
  .new-theme-builder__hf-style-label {
    flex-basis: 210px !important;
    // padding-left: 50px;
  }

  .tox-toolbar-overlord {
    padding: 0px !important;
  }
}
