.block-list {
  overflow: auto;

  &__sizes-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 14px 24px;
    margin: 0;
    margin-bottom: 25px;
  }

  &__filters {
    padding: 22px 20px;
    display: flex;
    flex-direction: column;
  }

  &__filters-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-of-type {
      margin-bottom: 18px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;

  }

  &__grid {
    padding: 0 12px 24px 24px;
    display: grid;
    grid-template-columns: repeat(12, 22px);
    grid-template-rows: repeat(8, 22px);
    grid-auto-rows: 22px;
    grid-gap: 2px;
    //max-height: 350px;
    overflow-x: hidden;
    margin-top: 20px;
  }
}

.favorites-heading {
  padding-left: 24px;
}