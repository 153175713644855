.edit-block-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 8px 0 50px 0;

  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    color: $color-dark;
    margin-bottom: 18px;
  }

  &__text,
  &__checkbox {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $color-dark;
  }

  &__bottom-actions {
    width: 100%;
    // padding: 0 24px;
  }

  &__bottom-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0;
  }

  & .ant-tabs-tab {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    & .ant-tabs-tab-btn {
      color: $color-dark;
    }
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color-primary;
  }

  & .ant-tabs-ink-bar {
    background: $color-primary;
  }

  &__form {
    width: 100%;
    padding: 0 80px;
  }

  &__list {
    width: 100%;
    max-height: 200px;
    overflow: auto;
    margin: 0 0 14px;
  }
  &__list-entry {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #aeaeae;
    &:last-child {
      border-bottom: none;
    }
    padding-bottom: 4px;
    margin: 8px;
  }
  &__list-entry-title {
    color: #333;
  }
  &__list-entry-status {
    text-transform: capitalize;
    color: #aeaeae;
  }
  &__show-impacted {
    margin-bottom: 14px;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-tabs {
    width: 100%;
  }
}

.save-block-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    color: $color-dark;
    margin-bottom: 18px;
  }

  &__text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $color-dark;
  }

  &__form {
    width: 100%;
    padding: 0 56px 40px 56px;
  }
}
