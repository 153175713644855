.qoolection-preview-modal {
  height: 891px;
  position: relative;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__left-icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      color: $color-primary;
    }

    &--disabled {
      cursor: not-allowed;
      color: $color-grey;

      &:hover {
        color: $color-grey;
      }
    }
  }
  
  &__right-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      color: $color-primary;
    }

    &--disabled {
      cursor: not-allowed;
      color: $color-grey;

      &:hover {
        color: $color-grey;
      }
    }
  }

  & .theme-preview__phone-container {
    margin-top: 0;
  }

  &__blank-bg {
    width: 308px;
    height: 585px;
    background-color: $color-white;
  }

  .theme-preview__qoo-content {
    .text-content-container p {
      margin-bottom: 0;
    }
  }
}