.download-qoolection-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 37px 44px;
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    color: $color-dark;
    margin-bottom: 20px;
  }
  &__description {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin-bottom: 24px;
    text-align: center;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 40px;
  }
  &__button {
    flex: 1;
  }
  &__form {
    width: 100%;
  }
  &__row {
    width: 100%;
    border-bottom: 1px solid #E1E5E9;
    // hover effect
    transition: background-color .2ms ease-in-out;
    &:hover,
    &:has(.ant-radio-checked),
    &:has(.ant-checkbox-checked) {
      background-color: $color-light-grey;
    }
  }
  &__label {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  &__icon {
    color: $color-primary;
    cursor: pointer;
  }
  &__checkbox {
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    .ant-radio-inner {
      &::after {
        transform: scale(0.5);
        background: $color-primary;
      }
    }
  }

  //TOOLTIPS
  &__tooltip {
    color: $color-primary;
    padding: 14px 18px;
  }
  &__tooltip-label {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &__tooltip-image svg {
    width: 100%;
  }

  // ANT DESIGN ADJUSTMENTS
  .ant-form-item {
    margin: 0;
  }
  .ant-radio-group {
    width: 100%;
    font-size: 14px;
    // indent radio button groups
    .download-qoolection-modal__row {
      padding-left: 26px;
    }
  }
  .ant-radio-wrapper {
    margin-right: 2px;
  }
  .ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
    box-shadow: none;
  }
  .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
    border: 0;
  }
  .ant-checkbox,
  .ant-radio {
    margin-right: 8px;
  }
  .ant-checkbox-inner,
  .ant-radio-inner {
    background-color: transparent;
  }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border: 0;
  }
  .ant-checkbox-wrapper::after,
  .ant-radio-wrapper::after
  {
    content: none;
  }
  .ant-checkbox-checked::after {
    border: 0;
  }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 0;
    content: '';
    display: inline-block;
    background: url('../../../src/icons/checkmark_blue.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    transform: none;
    position: initial;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 0;
  }
  .ant-radio-checked::after {
    border: 0;
  }
  .ant-radio-inner {
    border: 0;
  }
  .ant-radio-disabled .ant-radio-inner {
    background-color: transparent;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
  .ant-form-item-has-error .ant-form-item-explain-error {
    margin-top: 5px;
    margin-left: 8px;
  }
}