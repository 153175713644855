.block-types-list {
  display: flex;
  align-items: center;

  &__btn {
    width: 60px;
    height: 56px;
    border-bottom: 1px solid $color-grey;
    color: $color-dark-grey;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 7px 0;
    min-width: 72px;
    cursor: pointer;
    transition: all .2s ease-in;

    &:hover,
    &--active {
      // background-color: $color-light-grey;
      color: $color-primary;
    }

    &--active {
      border-bottom: 3px solid $color-primary;
    }

    &--disabled {
      opacity: 0.25;
      cursor: default;

      &:hover {
        background-color: transparent;
        color: $color-dark-grey;
      }
    }
  }

  &__icon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__icon-additional {
    width: 14px;
    height: 14px;
    position: absolute;
    right: -10px;
    bottom: 0;
  }

  &__name {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: $font-toolbar-weight;
    font-size: $font-toolbar-size;
    line-height: $font-toolbar-line-height;
    text-align: center;
    color: $color-dark;
    // margin-top: 3px;
    text-transform: capitalize;
  }

  &__divider {
    height: 48px;
    width: 1px;
    margin: 0 16px;
    background-color: $color-grey;
  }

  &__endnotes-overlay {
    width: 170px;
    background-color: $color-white;
    box-shadow: 1px 1px 6px rgba(217, 217, 217, 0.21);
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__endnotes-overlay-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px 12px;
    cursor: pointer;
    transition: all 0.2s ease-in;

    &:hover {
      background-color: $color-light-grey;

      &>svg,
      &>span {
        color: $color-primary;
      }
    }

    &>span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: $color-dark;
    }

    &>svg {
      width: 24px;
      height: 24px;
      color: $color-dark-grey;
    }

    &>span,
    &>svg {
      transition: all 0.2s ease-in;
    }
  }
}