.delete-modal {

  & .ant-modal-body {
    padding: 35px;
  }

  .ant-modal-content {
    & .ant-modal-header {
      border-bottom: 0 !important;
    }
  }

  .ant-modal-content {
    & .ant-modal-footer {
      border-top: 0 !important;
    }
  }

  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    margin-bottom: 18px;

    &-element {
      // text-transform: capitalize;
    }
  }

  &__subtitle {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 30px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  &__btn {
    width: 160px;
  }

  &__elements-list {
    margin-bottom: 24px;
  }

  &__element {
    display: flex;
    align-items: center;
    gap: 12px;

    &>span {
      color: $color-dark;
      font-family: $font-secondary;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
    }
  }
}

// TABLE DELETE POPUP
.ant-popover {
  &-message {
    justify-content: center;
    &-icon {
      display: none;
    }
  }
  &-buttons {
    justify-content: center;
    & .ant-btn {
      width: 75px;
    }
  }
}