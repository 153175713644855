.history-modal {
  display: flex;
  height: 891px;

  &__versions {
    width: 560px;
    padding: 32px 80px 54px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid $color-grey;
  }

  &__preview {
    flex-basis: 540px;
    position: relative;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;

    & .theme-preview {
      background: none;
    }

    & .theme-preview__phone-container {
      margin-top: 0;
    }

    & .text-content-container p {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    color: $color-dark;
    margin-bottom: 18px;
  }

  &__name {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin-bottom: 24px;

    & span:last-of-type {
      color: $color-darker-grey;
    }
  }

  &__versions-list {
    max-height: 640px;
    overflow-y: auto;
    align-self: stretch;
  }

  &__version {
    width: 100%;
    height: 64px;
    border-bottom: 1px solid $color-light-grey;
    cursor: pointer;
    padding: 20px 12px 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    transition: all 0.2s ease-in;

    &:hover,
    &--active {
      background-color: $color-light-grey;
    }

    & .ant-tag {
      border: none;
    }
  }
}