.ant-statistic-content-prefix {
  .anticon {
    vertical-align: baseline;
    position: relative;
    top: -0.2ex;
  }
}

// .ant-checkbox-checked {
//     .ant-checkbox-inner {
//         background-color: #259dab !important;
//         border-color: #259dab !important;
//     }
// }

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #4f66a0 !important;
    border-color: #4f66a0 !important;
  }
}

.block-properties {
  .ant-radio .ant-radio-inner {
    width: 20px;
    height: 20px;
  }
  .ant-radio-checked .ant-radio-inner {
    background: $color-primary;
    &::after {
      width: 8px;
      height: 8px;
      margin-top: -4px;
      margin-left: -4px;
      background: #fff;
    }
  }
}
.images-page {
  .ant-card-head {
    background-color: $color-primary !important;
    border-radius: 3px 3px 0 0 !important;
  }
}

.ant-card {
  border-radius: 3px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: $color-primary !important;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $color-primary !important;
}

// ANT SPIN LOADER
.ant-spin-dot {
  &-spin {
    animation: antRotate 1s infinite linear;
  }
  &-item {
    background-color: $color-primary;
  }
}

// ANT TOOLTIP
.ant-tooltip {
  &-inner {
    border-radius: 3px;
  }
}

.ant-tooltip-arrow-content::before {
  clip-path: inset(33% 33%) !important;
}

.ant-tooltip-inner {
  background: $color-primary;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #4962e2;
}
