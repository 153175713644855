label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  .card-wrapper {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 425px;
    max-width: 425px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('<md') {
      min-width: 320px;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: $color-white;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      width: 50%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: flex-start;
  }
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.ant-card {
  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: $color-white;
    }
  }
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: $color-white;
  // color: #2c2638;
  color: $color-white;
  // background-color: #216c97;
  background-color: #36a9e1;
  text-transform: capitalize;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.panel-primary > .panel-heading {
  color: $color-white;
  background-color: #216c97;
  border-color: #216c97;
}

.panel-heading .panel-title span {
  background-color: #216c97 !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: $color-white;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-form-item-label {
  min-width: 100px;
}

// input, select { font-size: 100% !important; }

.media-dragger {
  .ant-btn svg {
    margin-left: 0;
  }
  .ant-upload-list-item {
    height: 36px;
    &-info {
      padding-left: 8px;
    }
  }
  .ant-card {
    width: 100% !important;
  }
  .ant-card-head {
    display: none;
  }
  .ant-upload {
    height: 230px;
  }
  .ant-upload-drag-icon {
    color: $color-primary;
    margin-bottom: 16px;
  }
  .ant-upload-text {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #2a3a49;
    margin-bottom: 18px !important;
  }
}

.ant-upload-browse-btn {
  width: 142px;
  height: 32px;
  border-radius: 3px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: $color-primary;
  border: 1px solid $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// ENDNOTES

.add-author-btn {
  .ant-form-item-control-input {
    min-height: auto;
  }
}

.auth-message {
  font-family: $font-secondary;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  border: 1px solid $color-dark-grey;
  color: $color-dark-grey;
  &--error {
    border: 1px solid $color-warning-red;
    color: $color-warning-red;
  }
  &--success {
    border: 1px solid $color-success-green;
    color: $color-success-green;
  }
}

// NUMBER INPUT
.num-input {
  .ant-input-number-handler-wrap {
    opacity: 1;

    * {
      border: none;
    }

    .anticon {
      font-size: 0.625rem !important;
    }
  }
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: $color-darker-grey;
  }
}

// FORM DYNAMIC ANSWERS
.form-answer-header {
  font-family: $font-secondary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0 1rem;

  span {
    line-height: 1rem;
  }

  &__remove {
    cursor: pointer;
    color: $color-primary;
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
}

.ant-input-number:hover {
  border-color: $color-primary;
}
