// .ant-menu-item-selected{
//   background-color: rgb(37,157,171) !important;
// }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  // background-color: #c0c0c2;
  background-color: #2d323d;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
