.qoos-list {
  background-color: $color-light-grey;
  padding: 20px 40px;

  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }

  &__dropdowns {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filter-input {
    width: 168px;

    & svg {
      width: 13px;
      height: 13px;
    }
  }

  &__qoos {
    display: flex;
    flex-wrap: wrap;
  }
}

// .qoos-list {
//   width: 100%;
//   height: 100%;
//   margin-left: 460px;
//   padding: 32px 70px;

//   &__heading {
//     font-style: normal;
//     font-weight: bold;
//     font-size: 18px;
//     line-height: 21px;
//     color: #000000;
//     margin-bottom: 18px;
//   }

//   &__filters {
//     display: flex;
//     align-items: center;
//     margin-bottom: 30px;

//     & .ant-input-affix-wrapper-lg {
//       max-width: 200px;
//       border-radius: 6px;
//       margin-right: 10px;
//     }

//     & button {
//       border-radius: 6px;
//     }
//   }

//   &__qoos {
//     display: flex;
//     flex-wrap: wrap;
//   }
// }