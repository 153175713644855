.approval-modal {
  padding: 30px 80px 38px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    color: $color-dark;
    margin-bottom: 20px;
  }

  &__btn {
    margin-bottom: 8px;
  }

  &__btns-container {
    display: flex;
    gap: 10px;

    & .approval-modal__btn {
      width: 195px;
    }
  }

  &__text {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin-bottom: 32px;
  }

  &__form {
    width: 100%;
  }
}