.qoolections-content {
  height: 100%;
}

.qoolection-qoos {
  height: 100%;

  .ant-pagination {
    position: unset;
    bottom: unset;
    width: unset;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  .table-header__search {
    min-width: 152px;
    width: 152px;
    height: 32px;
  }

  .choice-btn-group {
    margin-right: 8px;
  }

  .choice-btn {
    flex-basis: 50%;
    width: 104px;
  }

  .table-header__status-select {
    width: 151px;
    margin-right: 15px;
  }

  .colors-tab__assignment-link {
    margin-bottom: 0;
    padding-right: 10px;
  }

  .grid-narrow-manage .ant-list-items {
    grid-column-gap: 13px;
    column-gap: 13px;
    grid-row-gap: 21px;
    row-gap: 21px;
  }
}

.edit-qoolection {
  .item-name-manage {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: $color-dark;
    margin-top: 8px;
  }

  .text-background__border-inputs {
    margin-bottom: 24px;
  }

  .edit-qoolection-content {
    padding: 26px 53px;
    padding-right: 39px;
    background: #fff;
  }

  .grid-narrow-manage {
    padding: 0;
    margin-top: 41px;
  }

  .qoolections-content {
    display: flex;

    .edit-block-content {
      max-height: calc(100vh - 80px);
      overflow-y: auto;
    }
  }

  .qoolection-added-qoos {
    width: 530px;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    border-left: 1px solid #d5dfe8;
    background: $color-off-white;
    font-family: 'Noto Sans', sans-serif;
    padding: 24px;
    display: flex;
    flex-direction: column;

    .added-qoos-title-row {
      margin-bottom: 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .added-qoos-clear {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $color-primary;
      cursor: pointer;
    }
  }

  .added-qoos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px);
    grid-column-gap: 21px;
    column-gap: 21px;
    grid-row-gap: 16px;
    row-gap: 16px;

    .added-qoo {
      &-card {
        width: 140px;
        height: 257px;
        border: 1px solid $color-grey;
        border-radius: 3px;
        position: relative;

        &:hover {
          .added-qoo-remove {
            display: flex;
          }
        }
      }

      &-remove {
        display: none;
        position: absolute;
        right: -12px;
        top: -12px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        position: absolute;
        background: $color-white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .ant-card {
      width: 100%;
      height: 257px;
      margin-bottom: 10px;
      border: 1px solid hsl(208, 29%, 87%);

      &-body {
        padding: 20px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          text-align: center;
        }
      }
    }

    .add-qoos-here {
      border: 1px dashed $color-medium-grey;
      background: $color-off-white;
      border-radius: 3px;
    }

    .add-blank-qoo {
      cursor: pointer;
      text-align: center;
      border: 1px solid $color-primary;
      color: $color-primary;
      border-radius: 3px;

      .ant-card-body {
        flex-direction: column;
      }
    }
  }

  .next-step-row {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }
}

.qoolection-category-select {
  width: 100%;
  margin-bottom: 14px;

  &--first {
    margin-top: 14px;
  }
}

.qoolection-qoos-list {
  margin: 16px 0;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  // max-height: 530px;
  // overflow-y: auto;
  margin-right: -16px;
}

.qoolection__menu-section {
  padding: 40px 44px;
}

.menu-panel__add-txt-btn {
  margin-top: 10px;
}

.menu-panel__heading-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $color-primary;
    cursor: pointer;
  }
}

.qoolection__droppable-qoos-list {
  padding: 16px 24px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
  transition: background-color 0.2s ease;
  position: relative;

  &--item-over {
    background-color: $color-dark-3;
  }

  &--add-info {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.qoolection__qoo-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  position: relative;

  &--in-grid {
    cursor: move;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 132px;
      right: -10px;
      width: 20px;
      height: 1px;
      background-color: $color-grey;
    }

    &--last::after {
      display: none;
    }
  }

  &--in-info {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 132px;
      right: -24px;
      width: 24px;
      height: 1px;
      background-color: $color-grey;
    }

    &--last::after {
      display: none;
    }
  }

  &:hover .qoolection__qoo-card-menu-icon {
    opacity: 1;
  }

  &.react-draggable-dragging::after {
    display: none;
  }
}

.qoolection__qoo-card-menu-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s ease;
  z-index: 2000;

  &--visible {
    opacity: 1;
  }
}

.qoolection__qoo-card-title {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $color-dark;
  text-align: center;
  word-break: break-word;
  max-width: 140px;
}

.qoolection__qoo-card-number {
  position: absolute;
  top: 4px;
  left: 14px;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $color-white;
  background-color: $color-primary;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qoolection__qoo-card-dropdown {
  position: absolute;
  top: 24px;
  right: 2px;
  background-color: $color-white;
  box-shadow: 0px 0px 15px rgba(42, 58, 73, 0.2);
  z-index: 10;
}

.qoolection__qoo-card-dropdown-item {
  width: 142px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  & svg {
    color: $color-darker-grey;
    transition: all 0.2s ease;
  }

  & > span {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    transition: all 0.2s ease;
  }

  &:hover {
    background-color: $color-light-grey;

    & > svg,
    & > span {
      color: $color-primary;
    }
  }
}

.qoolection__drag-info,
.qoolection__add-blank-qoo,
.qoolection__blank-qoo {
  width: 140px;
  height: 265px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: $color-darker-grey;

  & > svg {
    width: 18px;
    height: 18px;
  }
}

.qoolection__drag-info {
  background: $color-off-white;
  border: 1px dashed $color-medium-grey;
}

.qoolection__add-blank-qoo,
.qoolection__blank-qoo {
  background: $color-white;
  border: 1px solid $color-grey;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $color-primary;
    border-color: $color-primary;
  }
}

.qoolection__blank-qoo--in-grid {
  cursor: move;

  &:hover {
    border-color: $color-grey;
  }
}

.qoolection__main-content {
  background-color: $color-off-white;
}

.qoolection__arrange-qoos {
  padding: 16px 24px;
  height: 100%;
  overflow-x: auto;
  // display: flex;
  // flex-wrap: wrap;
  // gap: 24px;
}

@keyframes open-drawer {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes close-drawer {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.preview-drawer {
  &__content-container {
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  &__content {
    padding: 40px 40px 30px;
    min-height: 657px;
    background-color: $color-white;
    width: 100%;
    // animation: open-drawer 0.5s ease;
    overflow-x: auto;

    // &--closed {
    //   width: 0;
    //   animation: close-drawer 0.5s ease;
    // }
  }

  &__close-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: pointer;
    color: $color-white;

    & > svg {
      width: 36px;
      height: 36px;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    color: $color-dark;
  }

  &__qoos-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 20px;
  }

  &__qoo-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 91px;
      right: -20px;
      width: 20px;
      height: 1px;
      background-color: $color-grey;
    }

    &--last::after {
      display: none;
    }
  }

  &__qoo-title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    text-align: center;
  }

  &__add-btn {
    width: 200px;
    position: absolute;
    right: 40px;
    bottom: 30px;
    animation: fade-in 1s ease;
  }

  &__buttons-container {
    position: absolute;
    right: 40px;
    bottom: 30px;
    display: flex;
    gap: 10px;
  }
}

.cookie-modal-wrapper {
  position: absolute;
  z-index: 100;
  background-color: red;
  border: 2px solid red;
}

.qoolection__add-blank-qoo,
.qoolection__qoo-card {
  * {
    user-drag: none;
    -webkit-user-drag: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}
