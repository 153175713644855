.edit-block {
  height: 100%;
  background-color: $color-white;

  &__content {
    background-color: #e5e5e5;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 72px);
  }

  .mce-content-body,
  .mce-edit-focus {
    outline: none;
  }

  .react-grid-placeholder {
    opacity: 0.5;
    background: transparent;
    border: 1px solid $color-primary;
  }
}

.pre-create-block {
  width: 100%;
  height: 100%;
  padding: 20px 24px;

  &__form {
    margin: 20px 0;

    & .ant-form-item {
      margin-bottom: 12px;
    }

    & .ant-form-item-label {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: $color-dark;

      .ant-form-item-required {
        &::before {
          margin-right: 0 !important;
          content: '' !important;
        }

        &::after {
          content: '*';
          display: inline-block;
          margin-left: 2px;
          font-size: 16px;
          font-family: SimSun, sans-serif;
        }
      }
    }
  }

  .ant-select-multiple {
    .ant-select-selector {
      .ant-select-selection-overflow {
        padding: 8px;
        gap: 4px;

        &-item {
          &-suffix {
            display: none;
          }

          &-remove {
            .ant-select-multiple .ant-select-selection-item-remove > * {
              line-height: 0;
            }
          }
        }
      }

      .ant-select-selection-item {
        height: 24px;
        line-height: normal;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 4px 8px;

        &-content {
          font-size: 14px;
        }

        &-remove {
          padding: 0;
        }
      }
    }

    .ant-select-selection-item {
      background: $color-primary-3;
      color: $color-primary;
      border: none;
      border-radius: 3px;
      font-weight: 700;

      &-remove {
        display: flex;
        align-items: center;
        padding-top: 4px;
        color: $color-primary;
        font-size: 15px;
      }
    }
  }
}

.edit-block-inner-header {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;

  &--qoolections {
    height: 100%;
    justify-content: space-between;
  }
}

.edit-block-header-buttons {
  display: flex;
  align-items: center;
  gap: 25px;
}

.edit-block-undo-redo {
  display: flex;
  align-items: center;
  gap: 12px;

  &__btn {
    cursor: pointer;
    color: $color-dark;

    &--disabled {
      color: $color-grey;
      cursor: auto;
    }
  }
}

.edit-block-save-cnc {
  display: flex;
  gap: 8px;

  &__preview {
    width: 120px;

    &:focus {
      background: none;
    }

    &:hover {
      // background: $color-primary-3;
    }

    &--active {
      // background: $color-primary-3 !important;
      color: $color-primary;
      border-color: $color-primary;
    }
  }

  &__save {
    min-width: 120px;
    width: 104px;
  }

  &__cnc-btn {
    width: 32px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.edit-block-content {
  max-height: calc(100vh - 136px);
  overflow-y: hidden;
}

// u EditBlock-copy.js. Ako na kraju ne bude trebalo, obrisati
.block-type-info {
  &__heading {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #2e4257;
    display: flex;
    align-items: center;
    padding: 26px 25px 24px 35px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    & svg {
      width: 18px;
      height: 18px;
      fill: #2e4257;
    }
  }

  &__icon {
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
  }

  &__selected {
    text-transform: capitalize;
    margin-left: 10px;
  }
}

.new-block-form {
  padding: 20px 25px 20px 35px;
}

.block-types-grid {
  padding: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  &__item {
    background-color: #eaf1f7;
    width: 118px;
    height: 108px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #000;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    &--selected,
    &:hover {
      background-color: #dbeaf5;
    }

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  &__item-name {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 3px;
  }
}
