.edit-qoo {
  height: 100%;
  //position: relative;
  overflow: hidden;

  &__create-from {
    margin-top: 20px;
  }

  &__content {
    background-color: $wht;
    width: 100%;
    height: 100%;
    border-top: 1px solid #dbeaf5;
  }
}

.new-qoo-info {
  padding: 25px 30px 25px 25px;
}

.create-options-list {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}

.qoo-header-image-wrapper {
  height: 100%;
  .ant-image,
  .ant-image-img {
    height: 100%;
    max-height: 100%;
  }
  .ant-image-img {
    object-fit: contain;
  }
}

// .add-qoo-side {
//   background-color: #F9F9F9;
//   //width: 400px;
//   height: 100%;
//   padding: 0 25px 35px;
//   position: relative;
//   max-width: 400px;

//   &::before {
//     content: '';
//     height: 1px;
//     width: 100%;
//     background: #000;
//     opacity: 0.1;
//     position: absolute;
//     left: 0;
//     top: 66px;
//   }

//   &__heading {
//     font-style: normal;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 16px;
//     color: #2E4257;
//     padding: 26px 0 24px;
//     margin: 0;
//   }

//   &__form {
//     padding: 20px 0;
//   }

//   &__form-item {
//     margin-bottom: 12px;
//     justify-content: space-between;

//     & .ant-form-item-control {
//       max-width: 242px;
//     }
//   }

//   &__submit-btn {
//     width: 242px;
//     height: 32px;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 16px;
//     text-align: center;
//     text-transform: none;
//     padding: 0;
//     background-color: $c-blue;
//     border: none;
//   }
// }

// .qoo-builder {
//   height: 100%;
//   display: flex;
//   justify-content: space-between;

//   &__blocks-sidemenu {
//     width: 60px;
//     height: 100%;
//     background-color: $c-blue;
//     color: $wht;
//     padding-top: 7px;
//   }

//   &__blocks-sidemenu-item {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 5px 0;
//     margin-bottom: 5px;
//     cursor: pointer;

//     &:hover,
//     &--selected {
//       background-color: #2F73B8;
//     }
//   }

//   &__blocks-sidemenu-icon {
//     font-size: 16px;
//     line-height: 18px;
//     margin-bottom: 5px;
//   }

//   &__blocks-sidemenu-text {
//     font-style: normal;
//     font-weight: normal;
//     font-size: 10px;
//     line-height: 12px;
//     text-align: center;
//     text-transform: uppercase;
//   }

//   &__mobile {
//     width: 420px;
//     height: 750px;
//     background-color: chartreuse;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }

//   &__customize-qoo-sidemenu {
//     width: 375px;
//     height: 100%;
//     background-color: #F9F9F9;
//   }

//   &__customize-qoo-sidemenu-first-item .ant-menu-submenu-title span:last-of-type {
//     position: absolute;
//     top: 50%;
//     right: 16px;
//   }

//   &__edit-qoo-info {
//     padding: 20px 18px 25px 20px;
//   }

//   &__bg-color {
//     display: flex;
//     align-items: center;
//     padding: 0 0 27px 20px;
//   }

//   &__bg-colors-list {
//     display: flex;
//     flex-direction: row;
//   }

//   &__bg-color-item {
//     width: 25px;
//     height: 25px;
//     border-radius: 4px;
//     cursor: pointer;

//     &:not(:first-of-type) {
//       margin-left: 7px;
//     }

//     &:first-of-type {
//       position: relative;

//       &::before {
//         content: '';
//         width: 32px;
//         height: 2px;
//         background-color: #FF0000;
//         position: absolute;
//         top: 47%;
//         left: -4px;
//         transform: rotate(-45deg);
//       }
//     }
//   }
// }
