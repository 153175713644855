.qoo-blueprint {
  width: 115px;
  height: 210px;
  border: 1px solid #C6C6C6;
  background-color: #E4ECF4;
  border-radius: 12px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  margin: 10px 12px;

  &--active {
    border: 2px solid #3B8DE0;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(3 1fr);
    grid-template-rows: repeat(5 1fr);
    gap: 12px;
    height: 100%;
  }

  &__create-from {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.box-blue {
  background-color: rgba(137, 196, 255, 0.5);
}

.box-orange {
  background-color: rgba(232, 189, 164, 0.5)
}

.box-1 {
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
}
.box-2 {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}
.box-3 {
  grid-column: 2 / span 2;
  grid-row: 2 / span 2;
}
.box-4 {
  grid-column: 1 / span 1;
  grid-row: 3 / span 3;
}
.box-5 {
  grid-column: 2 / span 2;
  grid-row: 4 / span 2;
}