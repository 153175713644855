.blocks-selector {
  // padding: 25px 25px 200px 25px;
}

.blocks-to-select-grid {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(12, 24px);
  grid-auto-rows: 24px;
  gap: 2px;

  & .text-content-container > p {
    margin-bottom: 0;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  margin-left: -50px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  position: absolute;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  overflow: visible;
}

.tooltip-top::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
