.library {
  display: flex;
  height: 100%;

  .ant-menu-sub.ant-menu-inline {
    background: transparent;
  }

  .ant-menu-inline {
    border-right: 0;
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
    color: $color-dark;
  }

  .ant-menu-submenu-selected {
    color: $color-dark;
  }

  .ant-menu-submenu-active,
  .ant-menu-item-active {
    color: $color-dark;
  }

  .ant-menu-submenu-title {
    &:hover {
      color: $color-dark;
    }
    &:active {
      background: transparent;
    }
  }

  .ant-menu-submenu-title[aria-expanded='false'] .ant-menu-submenu-expand-icon {
    transform: rotate(180deg);
    transition: 0.2s ease-in-out transform;
  }
  .ant-menu-submenu-title[aria-expanded='true'] .ant-menu-submenu-expand-icon {
    transform: rotate(0deg);
    transition: 0.2s ease-in-out transform;
  }

  .ant-menu-submenu-expand-icon {
    top: 40%;
  }

  &__menu-item {
    color: $color-dark;
    font-size: 16px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 500;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    svg {
      color: $color-dark-grey;
    }
    &:hover {
      // background: $color-light-grey;
      color: $color-dark !important;

      svg {
        color: $color-primary;
      }
    }
    .ant-menu-title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        color: $color-primary-dark;
      }
    }
    // Align all menu icons so that they don't depend on size
  }
  &__menu-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px !important;
    height: 20px !important;

    svg {
      width: 20px !important;
      max-height: 20px !important;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    // background: $color-light-grey;
    svg {
      color: $color-primary;
    }
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }

  &__submenu {
    .ant-menu-submenu-title {
      font-size: 12px;
      color: $color-dark;
      font-family: 'Noto Sans', sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }

  .table-header {
    * {
      width: auto;
    }
    &__search {
      width: 320px;
    }
    &__select {
      width: 200px;
      text-align: left;
    }
    .ant-input {
      width: 100%;
      font-family: 'Noto Sans', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: $color-dark-grey;
    }
  }

  .infinite-scroll-component {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .image-overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.63%, #000000 126.54%);
    &__top-row {
      display: flex;
      justify-content: flex-end;
      gap: 3px;
      padding: 4px 4px 0;
    }
    &__icon {
      width: 24px;
      height: 24px;
      background: #fff;
      color: $color-dark;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:first-child {
        svg {
          width: 9px;
          height: 12px;
        }
      }
      &:last-child {
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
    &__bottom-row {
      padding: 0 10px 8px;
      text-align: left;
      span {
        font-family: 'Noto Sans', sans-serif;
        font-weight: 500;
        color: #fff;
        font-size: 14px;
      }
    }
  }
  .video-overlay {
    &__top-row {
      display: flex;
      justify-content: space-between;
      &-icons {
        display: flex;
        gap: 3px;
      }
    }
    &__duration {
      color: #fff;
      font-family: 'Noto Sans', sans-serif;
      font-size: 14px;
    }
  }
}
.media-preview-modal {
  top: 50px;
  display: flex;
  justify-content: center;
  .ant-modal-body {
    text-align: center;
    padding: 0;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    width: fit-content;
  }

  &__asset {
    &:nth-child(2) {
      display: none;
    }
  }
}

.video-modal-library {
  .video-react-video {
    position: relative;
  }
  img {
    max-width: 90vw;
    // min-height: 50vh;
    max-height: 90vh;
  }
}

.library-asset {
  position: relative;
  background-color: #eee;
  display: block;
  width: 100%;
  margin-bottom: 15px;

  .video-play-btn {
    cursor: pointer;
    position: absolute !important;
    width: 100%;
    height: calc(100% - 28px);
    left: 0;
    bottom: 0;
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 28px;
  }
}
.image-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  &__img {
    height: calc(100% - 1em);
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

#icon-bar {
  .tox:nth-child(3),
  .tox:nth-child(4) {
    display: none !important;
  }
}

// MODAL FOR IMAGE PREVIEW
.ant-modal-wrap:has(.media-preview-modal) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-preview-modal {
  top: unset;
  padding-bottom: 0;
}

.references-library-dropdown:nth-child(2) {
  display: none;
}
