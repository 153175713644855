.phone-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100%;

  &-modal {
    .ant-modal-body {
      padding: 48px 0;
    }
  }

  & .marvel-device {
    margin-top: 30px;
    transform-origin: center top;
  }

  & .iphone-x {
    width: calc(375px * 0.7);
    height: calc(812px * 0.7);

    .notch {
      width: calc(210px * 0.7);
      height: calc(30px * 0.7);
      left: calc(108px * 0.78);
    }

    .camera {
      display: none;
      left: unset;
      right: 20px;
      top: calc(9px * 0.7);
    }

    .speaker {
      top: calc(9px * 0.7);
    }
  }

  & .iphone8 {
    width: calc(375px * 0.7);
    height: calc(667px * 0.7);

    .sensor {
      left: 90px;
      width: calc(16px * 0.7);
      height: calc(16px * 0.7);
      top: 51px;
    }
  }

  & .note8 {
    width: calc(400px * 0.7);
    height: calc(822px * 0.7);

    .sensors,
    .more-sensors {
      display: none;
    }

    .speaker {
      top: 22px;
    }
  }

  & .iphone8plus {
    width: calc(414px * 0.7);
    height: calc(736px * 0.7);

    .sensor {
      left: 100px;
      width: calc(16px * 0.7);
      height: calc(16px * 0.7);
      top: 57px;
    }
  }

  & .iphone5s,
  & .iphone5c {
    width: calc(320px * 0.8);
    height: calc(568px * 0.7);
    padding: calc(105px * 0.7), calc(22px * 0.7);

    .volume,
    .sleep {
      display: none;
    }
  }

  & .ipad {
    width: calc(576px * 0.7);
    height: calc(768px * 0.7);
  }

  & .nexus5,
  & .galaxys5 {
    width: calc(320px * 0.7);
    height: calc(568px * 0.7);

    .volume,
    .sleep,
    .camera {
      display: none;
    }
  }

  // &__select-device {
  //   position: absolute;
  //   left: 50%;
  //   bottom: 70px;
  //   transform: translateX(-50%);
  // }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-light-grey;
  }

  &__grid {
    width: 100%;

    & .text-content-container p {
      margin-bottom: 0;
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: -25px;
    font-size: 36px;
    cursor: pointer;
  }

  .ant-select {
    width: 200px;
  }

  & .text-content-container p {
    margin-bottom: 0;
  }
}
