.context-menu {
  background: $color-white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;

  &__group {
    padding: 4px 0;
    border-bottom: 1px solid $color-grey;
  }

  &__item {
    height: 48px;
    width: 256px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px 12px 13px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $color-light-grey;

      & svg,
      & .context-menu__action span {
        color: $color-primary;
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 10px;

    & svg {
      color: $color-darker-grey;
      transition: color 0.2s ease-in-out;
    }

    & span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $color-dark;
      transition: color 0.2s ease-in-out;
    }

    &--rotate {
      & svg {
        transform: rotate(180deg);
      }
    }
  }

  &__keyboard {
    background-color: rgba(213, 223, 232, 0.4);

    border-radius: 3px;
    padding: 2px 10px 3px 10px;

    & span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $color-darker-grey;
    }
  }
}