.collaborators-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.25rem 5rem 3.5rem;
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: $color-dark;
    margin-bottom: 0.75rem;
  }
  &__description {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-dark;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  &__users {
    width: 100%;
    border: 1px solid $color-grey;
    border-radius: 3px;
    padding: 1rem;
  }

  &__search {
    margin-bottom: 1.25rem;
  }

  &__user-list {
    list-style-type: none;
    padding: 0;
    padding-right: 0.75rem;
    margin: 0;
    max-height: 325px;
    overflow: auto;
    text-align: center;
  }
}

.collaborator {
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__details {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__avatar {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background: $color-primary;
    color: $color-off-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-secondary;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
  }

  &__name {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    color: $color-dark;
  }

  &__access {
    min-width: 100px;
  }
}

.flex-modifier {
  display: flex;
}

.icon-modifier {
  color: $color-primary;
}
