.qoolections-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.qoolections-grid {
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  padding: 0 28px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;

  &__card-container {
    position: relative;
  }

  &__card {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &:hover .qoolections-grid__qoolection-card-menu-icon {
      opacity: 1;
    }
  }

  &__card-content {
    position: relative;
    border: 1px solid transparent;

    &--border {
      border-color: $color-grey;
    }
  }

  &__options-wrapper {
    padding: 8px 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    transition: all .3s;
    z-index: 2000;

    &--visible {
      visibility: visible;
      opacity: 1;
      background: rgba(255, 255, 255, .9);
    }
  }

  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
  }

  &__pagination {
    padding: 24px 0;

    .ant-pagination {
      display: flex;
      justify-content: center;
      position: fixed;
      bottom: 0;
      width: calc(100vw - 260px);
      background: rgba(255, 255, 255, 0.75);
      padding: 10px 0;
    }
  
    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
      width: 32px;
      height: 32px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      margin: 0;

      .ant-pagination-item-link {
        border: none;
      }
    }
  
    .ant-pagination-prev {
      margin-right: 33px !important;
    }
  
    .ant-pagination-next {
      margin-left: 33px !important;
    }
  
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-item:hover .ant-pagination-item-link,
    .ant-pagination-item:hover a {
      color: $color-primary;
    }
  
    .ant-pagination-item-active {
      background: $color-primary-3;
      color: $color-primary;
      border: none;
    }
  }

  &__qoolection-card-menu-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &--visible,
    &--in-table {
      opacity: 1;
    }

    &--in-table {
      position: static;
    }
  }

  &__qoolection-card-dropdown {
    position: absolute;
    top: 24px;
    right: 2px;
    background-color: $color-white;
    box-shadow: 0px 0px 15px rgba(42, 58, 73, 0.2);
    z-index: 10;

    &--in-table {
      top: 100%;
      right: 0;
    }
  }

  &__qoolection-card-dropdown-item {
    width: 160px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: all 0.2s ease;

    & svg {
      color: $color-darker-grey;
      transition: all 0.2s ease;
    }

    &>span {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $color-dark;
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: $color-light-grey;

      &>svg,
      &>span {
        color: $color-primary;
      }
    }
  }
}