.arrange-panel {

  &__item {
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid #D5DFE8;
    border-left: 1px solid #D5DFE8;
    border-right: 1px solid #D5DFE8;
    cursor: move;

    &:first-of-type {
      border-radius: 3px 3px 0px 0px;
    }

    &:last-of-type {
      border-bottom: 1px solid #D5DFE8;
    }

  }

  &__item-nr {
    width: 20px;
    height: 20px;
    background: $color-medium-grey;
    border-radius: 3px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item-title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-dark;
    margin-right: auto;
  }

  & svg {
    width: 20px;
    height: 20px;
    color: #AEB8C0;
  }
}