//Success notification style

.ant-notification-notice {
  &.notification-success-option {
    border: 1px solid #25d06c;
    // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    box-shadow: none;
    border-radius: 3px;
    position: relative;
    padding: 26px 26px 26px 15px;
    width: 312px;

    .ant-notification-notice-content {
      min-height: 37px;
      height: 100%;
      display: flex;
      .ant-notification-notice-with-icon {
        display: flex;
        align-items: center;
      }
    }

    .ant-notification-notice-close {
      position: absolute;
      top: 4px;
      right: 10px;
      color: $color-darker-grey;

      &:hover {
        opacity: 0.8;
      }
    }

    .ant-notification-notice-icon {
      margin-top: 5px;
      margin-left: 0;

      svg {
        width: 37px;
        height: 37px;
      }
    }

    .ant-notification-notice-message {
      margin-left: 56px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Noto Sans', sans-serif;
      line-height: 21px;
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}

//Attention notification style

.ant-notification-notice {
  &.notification-attention-option {
    border: 1px solid #f0c557;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    position: relative;
    padding: 26px 26px 26px 15px;
    width: 312px;

    .ant-notification-notice-content {
      min-height: 37px;
      height: 100%;
    }

    .ant-notification-notice-close {
      position: absolute;
      top: 4px;
      right: 10px;
      color: $color-darker-grey;

      &:hover {
        opacity: 0.8;
      }
    }

    .ant-notification-notice-icon {
      margin-left: 0;

      svg {
        width: 40px;
        height: 36px;
      }
    }

    .ant-notification-notice-message {
      margin-left: 48px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Noto Sans', sans-serif;
      line-height: 21px;
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}

//Error notification style

.ant-notification-notice {
  &.notification-error-option {
    border: 1px solid #fb5757;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    position: relative;
    padding: 26px 26px 26px 15px;
    width: 312px;

    .ant-notification-notice-content {
      min-height: 37px;
      height: 100%;
    }

    .ant-notification-notice-close {
      position: absolute;
      top: 4px;
      right: 10px;
      color: $color-darker-grey;

      &:hover {
        opacity: 0.8;
      }
    }

    .ant-notification-notice-icon {
      margin-left: 0;

      svg {
        width: 36px;
        height: 40px;
      }
    }

    .ant-notification-notice-message {
      margin-left: 48px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Noto Sans', sans-serif;
      line-height: 21px;
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}

//Info notification style

.ant-notification-notice {
  &.notification-info-option {
    border: 1px solid $color-primary;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    position: relative;
    padding: 26px 26px 26px 15px;
    width: 312px;

    .ant-notification-notice-content {
      min-height: 37px;
      height: 100%;
    }

    .ant-notification-notice-close {
      position: absolute;
      top: 4px;
      right: 10px;
      color: $color-darker-grey;

      &:hover {
        opacity: 0.8;
      }
    }

    .ant-notification-notice-icon {
      margin-left: 0;

      svg {
        width: 37px;
        height: 37px;
      }
    }

    .ant-notification-notice-message {
      margin-left: 48px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Noto Sans', sans-serif;
      line-height: 21px;
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}
