.ant-input {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: $font-label-weight;
  font-size: $font-label-size;
  line-height: $font-label-line-height;
  color: $color-dark;
  &:hover {
    border-color: $color-primary;
  }
  &::placeholder {
    color: $color-dark-grey;
  }
  &-affix-wrapper {
    border: 1px solid $color-grey;
    border-radius: 3px;
    height: 36px;
    &:not(.ant-input-affix-wrapper-disabled):hover {
      box-shadow: none;
      border-color: $color-primary;
    }
    &:focus,
    &-focused {
      border-color: $color-primary;
      box-shadow: none;
    }
  }
  &:focus,
  &-focused {
    border-color: $color-primary;
    box-shadow: none;
  }
  &-clear-icon {
    font-size: 14px;
  }
}

:not(.ant-input-affix-wrapper) > .ant-input {
  height: 36px;
}
textarea.ant-input {
  height: auto !important;
}
// .ant-input-affix-wrapper.input-small {

//   border: 1px solid $color-grey;
//   border-radius: 3px;

//   & .ant-input {
//     font-family: $font-secondary;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 19px;
//     color: $color-dark;
//     height: 36px;
//     &::placeholder {
//       color: $color-dark-grey;
//     }
//   }

//   &:hover {
//     // border: 1px solid $color-dark-grey;
//     border: 1px solid $color-primary;
//   }

//   &.ant-input-affix-wrapper-focused {
//     border: 1px solid $color-primary;
//     box-shadow: 0px 0px 5px 1px $color-primary-3;
//     outline: none;

//     & .anticon {
//       color: $color-primary;
//     }
//   }
// }

.ant-input.input-small,
.ant-input.input-medium,
textarea.ant-input,
.ant-input-number.input-small {
  border: 1px solid $color-grey;
  border-radius: 3px;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  // font-size: 14px;
  // line-height: 19px;
  color: $color-dark;
  // height: 32px;

  &::placeholder {
    color: $color-dark-grey;
  }

  &:hover {
    // border: 1px solid $color-dark-grey !important;
    border: 1px solid $color-primary !important;
  }

  &:focus {
    border: 1px solid $color-primary !important;
    box-shadow: 0px 0px 5px 1px $color-primary-3 !important;
    outline: none !important;
  }
}

// .ant-input.input-small,
// .ant-input-number.input-small {
//   font-size: 14px;
//   line-height: 19px;
//   height: 32px;
// }
// .ant-input.input-medium {
//   font-size: 16px;
//   line-height: 26px;
//   height: 40px;
// }

.ant-form-item-has-error {
  & .ant-input {
    border-color: $color-warning-red;
  }

  & .ant-form-item-explain-error {
    color: $color-warning-red;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

.ant-input-number-focused {
  box-shadow: none;
}

.block-properties {
  .ant-input-number-handler-wrap {
    opacity: 1;
    .ant-input-number-handler {
      border: none;
    }
  }
}

.ant-input-number-handler {
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    font-size: 10px;
  }
}

.ant-input {
  font-size: 14px;
}

// .ant-input.input-medium {
//   font-size: 14px;
// }
// .ant-btn {
//   font-size: 14px !important;
// }
// .ant-input-affix-wrapper.input-small .ant-input {
//   font-size: 14px;
// }

.ant-input-number-input {
  height: 34px;
}

// hide InputNumber arrow if disabled
.ant-input-number-handler[aria-disabled='true'] {
  .anticon {
    opacity: 0;
  }
}

/* Disabled state */
.ant-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-slider-disabled .ant-slider-handle,
.transparency-slider--disabled {
  background-color: #fff !important;
  color: $color-dark-grey !important;
}
